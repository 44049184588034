import React from "react";
import { Link } from "react-router-dom";
import Card from "../CommonHOC/CardHOC/Card";
import Baseurl from "./ApiComponents/Baseurl/Baseurl";
import HOCAPI from "../CommonHOC/APIHOC/HOCAPI";

function Home(props) {
  return (
    <div>
      {/* Main Pannel */}

      <section
        style={{
          backgroundImage:
            "url('https://vshospitals.b-cdn.net/wp-content/uploads/2022/02/slider-mobile.webp')",
        }}
        id="hero"
        className="d-flex align-items-center justify-content-center"
      >
        <div className="container" data-aos="fade-up">
          <div
            className="row justify-content-center"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <div className="col-xl-6 col-lg-8">
              <h3 style={{ color: "white" }}>
                Touching lives, one patient at a time. Providing all our best
                <span>.</span>
              </h3>
              <h4 style={{ color: "white" }}>
                Because Your Life Matters. Fast, friendly and accurate care for
                you.
              </h4>
            </div>
          </div>

          <div
            className="row gy-4 mt-5 justify-content-center"
            data-aos="zoom-in"
            data-aos-delay="250"
          >
            <div className="col-xl-2 col-md-4">
              <div className="icon-box">
                <i className="ri-store-line"></i>
                <h3>
                  <Link to="/employee/list">
                    Doctors:<span>{props.data.totalDoctor}</span>
                  </Link>
                </h3>
              </div>
            </div>
            <div className="col-xl-2 col-md-4">
              <div className="icon-box">
                <i className="ri-bar-chart-box-line"></i>
                <h3>
                  <Link to="/service/list">
                    Services:<span>{props.data.totalService}</span>
                  </Link>
                </h3>
              </div>
            </div>
            <div className="col-xl-2 col-md-4">
              <div className="icon-box">
                <i className="ri-calendar-todo-line"></i>
                <h3>
                  <Link to="/patient/list">
                    Patients:<span>{props.data.totalPatient}</span>
                  </Link>
                </h3>
              </div>
            </div>
            <div className="col-xl-2 col-md-4">
              <div className="icon-box">
                <i className="ri-database-2-line"></i>
                <h3>
                  <Link to="/opd/list">
                    OPD TOday:<span>{props.data.todayOPD}</span>
                  </Link>
                </h3>
              </div>
            </div>
            <div className="col-xl-2 col-md-4">
              <div className="icon-box">
                <i className="ri-database-2-line"></i>
                <h3>
                  <Link to="/opd/list">
                    OPD Week:<span>{props.data.weekOPD}</span>
                  </Link>
                </h3>
              </div>
            </div>
            <div className="col-xl-2 col-md-4">
              <div className="icon-box">
                <i className="ri-database-2-line"></i>
                <h3>
                  <Link to="/opd/list">
                    Total OPD Amount:<span>{props.data.totalOpdAmount}</span>
                  </Link>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="clients" className="clients">
        <div className="container" data-aos="zoom-in">
          <div className="clients-slider swiper">
            <div className="swiper-wrapper align-items-center">
              <div className="swiper-slide active">
                <h1 className="img-fluid">MyHospital-Our Hospital</h1>
              </div>
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </section>
      <section id="features" className="features">
        <div  data-aos="fade-up">
          <div >
            <div className="row d-inline-flex text-center">
              {/* //  Card */}
              <div className="card text-center col-3  mx-5  makeres ">
                <div className="card-header my-2 bg-info-subtle">
                  <i className="text-primary">MyHospital</i>
                </div>
                <div className="card-body">
                  <h5 className="card-title">Service</h5>
                  <p className="card-text">
                    MyHospital Provides Best Services In The World!!
                  </p>
                  <Link to="/service">
                    <button className="btn btn-outline-primary">
                      TotalService{" "}
                      <span className="text-dark">
                        -: {props.data.totalService}
                      </span>
                    </button>
                  </Link>
                </div>
                <div className="card-footer text-muted ">
                  <i className="text-success">Updated Today!!</i>
                </div>
              </div>

              {/* Card */}
              <div className="card text-center col-3 my-2 mx-5  makeres">
                <div className="card-header  bg-info-subtle">
                  <i className="text-primary">MyHospital</i>
                </div>
                <div className="card-body">
                  <h5 className="card-title">Doctor</h5>
                  <p className="card-text">
                    MyHospital Have Best Doctors In The World!!
                  </p>
                  <Link to="/employeelist">
                    <button className="btn btn-outline-primary">
                      TotalDoctor{" "}
                      <span className="text-dark">
                        -: {props.data.totalDoctor}
                      </span>
                    </button>
                  </Link>
                </div>
                <div className="card-footer text-muted ">
                  <i className="text-success">Updated Today!!</i>
                </div>
              </div>

              {/* Card */}
              <div className="card text-center col-3 my-2 mx-5  makeres">
                <div className="card-header  bg-info-subtle">
                  <i className="text-primary">MyHospital</i>
                </div>
                <div className="card-body">
                  <h5 className="card-title">Pateint</h5>
                  <p className="card-text">
                    We Provide Many Facilities TO The Patient
                  </p>
                  <Link to="/patients">
                    <button className="btn btn-outline-primary">
                      TotalPatient{" "}
                      <span className="text-dark">
                        -: {props.data.totalPatient}
                      </span>
                    </button>
                  </Link>
                </div>
                <div className="card-footer text-muted ">
                  <i className="text-success">Updated Today!!</i>
                </div>
              </div>

              {/* Card */}
              <div className="card text-center col-3 my-2 mx-5  makeres">
                <div className="card-header  bg-info-subtle">
                  <i className="text-primary">MyHospital</i>
                </div>
                <div className="card-body">
                  <h5 className="card-title">OPD Today</h5>
                  <p className="card-text"> MyHospital Today OPD See IT!!</p>
                  <Link to="/opd">
                    <button className="btn btn-outline-primary">
                      TodayOPD{" "}
                      <span className="text-dark">-: {props.data.todayOPD}</span>
                    </button>
                  </Link>
                </div>
                <div className="card-footer text-muted ">
                  <i className="text-success">Updated Today!!</i>
                </div>
              </div>

              {/* Card */}
              <div className="card text-center col-3 my-2 mx-5  makeres">
                <div className="card-header  bg-info-subtle">
                  <i className="text-primary">MyHospital</i>
                </div>
                <div className="card-body">
                  <h5 className="card-title">OPD Week</h5>
                  <p className="card-text"> MyHospital Week OPD See IT!!</p>

                  <Link to="/opd">
                    <p className="btn btn-outline-primary">
                      WeekOPD{" "}
                      <span className="text-dark">-: {props.data.weekOPD}</span>
                    </p>
                  </Link>
                </div>
                <div className="card-footer text-muted ">
                  <i className="text-success">Updated Today!!</i>
                </div>
              </div>

              {/* Card */}
              <div className="card text-center col-3 my-2 mx-5  makeres">
                <div className="card-header  bg-info-subtle">
                  <i className="text-primary">MyHospital</i>
                </div>
                <div className="card-body">
                  <h5 className="card-title">Others</h5>
                  <p className="card-text">
                    MyHospital Have Best other Employee!!.
                  </p>
                  <Link to="/employeelist">
                    <button className="btn btn-outline-primary">
                      OtherStaff{" "}
                      <span className="text-dark">
                        -: {props.data.totalOtherStaff}
                      </span>
                    </button>
                  </Link>
                </div>
                <div className="card-footer text-muted ">
                  <i className="text-success">Updated Today!!</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section Pannel */}
      <section id="about" className="about">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div
              className="col-lg-6 order-1 order-lg-2"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <img
                src="https://images.hindustantimes.com/img/2022/03/17/550x309/5137c594-a0bb-11ec-9fac-87eaf699b9ab_1647550648253.jpg"
                className="img-fluid"
                alt=""
              />
            </div>
            <div
              className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <h3>
                Voluptatem dignissimos provident quasi corporis voluptates sit
                assumenda.
              </h3>
              <p className="fst-italic">
                Observation, Reason, Human Understanding, Courage; these make
                the physician." “Wear the white coat with dignity and pride—it
                is an honor and privilege to get to serve the public as a
                physician.” “Always remember the privilege it is to be a
                physician.
              </p>
              <ul>
                <li>
                  <i className="ri-check-double-line"></i> “To ensure good
                  health: eat lightly, breathe deeply, live moderately,
                  cultivate cheerfulness, and maintain an interest in life.” -
                  ...
                </li>
                <li>
                  <i className="ri-check-double-line"></i> Ullamco laboris nisi
                  ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                  reprehenderit in voluptate trideta storacalaperda mastiro
                  dolore eu fugiat nulla pariatur.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="services">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Services</h2>
            <p>Check our Services</p>
          </div>

          <div className="row">
            <div
              className="col-lg-4 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4>
                  <a href="">Emergency Ward</a>
                </h4>
                <p>
                  Our Hospital Provide Emergency ward Who actually needs this!!
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-file"></i>
                </div>
                <h4>
                  <a href="">ICU</a>
                </h4>
                <p>Our Hospital Provide all the Facilities to the Patient</p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-tachometer"></i>
                </div>
                <h4>
                  <a href="">General Ward</a>
                </h4>
                <p>
                  For Normal Issue There is a Normal Ward with all Facilities
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-world"></i>
                </div>
                <h4>
                  <a href="">Admit Rooms</a>
                </h4>
                <p>
                  When a patient Need to Admit A room is given to the Patient
                  and their Family.
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-slideshow"></i>
                </div>
                <h4>
                  <a href="">24*7</a>
                </h4>
                <p>Our Hospital Remains open always for you!! </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-arch"></i>
                </div>
                <h4>
                  <a href="">Fast Operates</a>
                </h4>
                <p>
                  If any Patient is in serious condition.Our Doctor operate on
                  him/her Immediately!!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="cta" className="cta">
        <div className="container" data-aos="zoom-in">
          <div className="text-center">
            <h3>Need Help?</h3>
            <p>
              If you Want to know anymore about My-Hospital You can Contact Us{" "}
            </p>
            <Link className="cta-btn" to="/help">
              Help
            </Link>
          </div>
        </div>
      </section>

      <section id="portfolio" className="portfolio">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Profile</h2>
            <p>Check our Profile</p>
          </div>

          <div
            className="row portfolio-container"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="col-lg-4 col-md-6 portfolio-item filter-app">
              <div className="portfolio-wrap">
                <img
                  src="https://thumbs.dreamstime.com/b/hospital-building-typical-modern-large-urban-43153826.jpg"
                  className="img-fluid"
                  alt=""
                />
                <div className="portfolio-info">
                  <h4>My-Hospital</h4>
                  <p>Your-Hospital</p>
                  <div className="portfolio-links">
                    <Link
                      to="https://thumbs.dreamstime.com/b/hospital-building-typical-modern-large-urban-43153826.jpg"
                      data-gallery="portfolioGallery"
                      className="portfolio-lightbox"
                      title="App 1"
                    >
                      <i className="bx bx-plus"></i>
                    </Link>
                    <Link to="portfolio-details.html" title="More Details">
                      <i className="bx bx-link"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-web">
              <div className="portfolio-wrap">
                <img
                  src="https://www.shutterstock.com/image-photo/modern-hospital-style-building-surrounded-260nw-352137068.jpg"
                  className="img-fluid"
                  alt=""
                />
                <div className="portfolio-info">
                  <h4>Hospital</h4>
                  <p>My-Hospital</p>
                  <div className="portfolio-links">
                    <Link
                      to={`https://www.shutterstock.com/image-photo/modern-hospital-style-building-surrounded-260nw-352137068.jpg`}
                      data-gallery="portfolioGallery"
                      className="portfolio-lightbox"
                      title="Web 3"
                    >
                      <i className="bx bx-plus"></i>
                    </Link>
                    <Link
                      to={`https://www.shutterstock.com/image-photo/modern-hospital-style-building-surrounded-260nw-352137068.jpg`}
                      title="More Details"
                    >
                      <i className="bx bx-link"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-card">
              <div className="portfolio-wrap">
                <img
                  src="https://aihms.in/blog/wp-content/uploads/2020/04/healthcare3.jpg"
                  className="img-fluid"
                  alt=""
                />
                <div className="portfolio-info">
                  <h4>Service</h4>
                  <p>Our Services</p>
                  <div className="portfolio-links">
                    <Link
                      to="https://aihms.in/blog/wp-content/uploads/2020/04/healthcare3.jpg"
                      data-gallery="portfolioGallery"
                      className="portfolio-lightbox"
                      title="Web 2"
                    >
                      <i className="bx bx-plus"></i>
                    </Link>
                    <Link to="portfolio-details.html" title="More Details">
                      <i className="bx bx-link"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-card">
              <div className="portfolio-wrap">
                <img
                  src="https://images.livemint.com/img/2019/12/15/1600x900/Ayushmanbharat-kvCD--621x414@LiveMint_1576399543406.jpg"
                  className="img-fluid"
                  alt=""
                />
                <div className="portfolio-info">
                  <h4>Ward</h4>
                  <p>General Ward</p>
                  <div className="portfolio-links">
                    <Link
                      to="https://images.livemint.com/img/2019/12/15/1600x900/Ayushmanbharat-kvCD--621x414@LiveMint_1576399543406.jpg"
                      data-gallery="portfolioGallery"
                      className="portfolio-lightbox"
                      title="Card 1"
                    >
                      <i className="bx bx-plus"></i>
                    </Link>
                    <Link
                      to="https://images.livemint.com/img/2019/12/15/1600x900/Ayushmanbharat-kvCD--621x414@LiveMint_1576399543406.jpg"
                      title="More Details"
                    >
                      <i className="bx bx-link"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-card">
              <div className="portfolio-wrap">
                <img
                  src="https://i.imgur.com/G46rvhX.jpg"
                  className="img-fluid"
                  alt=""
                />
                <div className="portfolio-info">
                  <h4>Ward</h4>
                  <p>Admit Room</p>
                  <div className="portfolio-links">
                    <Link
                      to="https://i.imgur.com/G46rvhX.jpg"
                      data-gallery="portfolioGallery"
                      className="portfolio-lightbox"
                      title="Card 3"
                    >
                      <i className="bx bx-plus"></i>
                    </Link>
                    <Link
                      to="https://i.imgur.com/G46rvhX.jpg"
                      title="More Details"
                    >
                      <i className="bx bx-link"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-web">
              <div className="portfolio-wrap">
                <img
                  src="https://trustees.aha.org/sites/default/files/2021-01/TI_0121_top_ten_900x400.jpg"
                  className="img-fluid"
                  alt=""
                />
                <div className="portfolio-info">
                  <h4>Happy Life</h4>
                  <p>Healthy Life</p>
                  <div className="portfolio-links">
                    <Link
                      to="https://trustees.aha.org/sites/default/files/2021-01/TI_0121_top_ten_900x400.jpg"
                      data-gallery="portfolioGallery"
                      className="portfolio-lightbox"
                      title="Web 3"
                    >
                      <i className="bx bx-plus"></i>
                    </Link>
                    <Link
                      to="https://trustees.aha.org/sites/default/files/2021-01/TI_0121_top_ten_900x400.jpg"
                      title="More Details"
                    >
                      <i className="bx bx-link"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Records */}
      <section id="counts" className="counts">
        <div className="container" data-aos="fade-up">
          <div className="row no-gutters">
            <div
              className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start"
              data-aos="fade-right"
              data-aos-delay="100"
            ></div>
            <div
              className="col-xl-7 ps-0 ps-lg-5 pe-lg-1 d-flex align-items-stretch"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <div className="content d-flex flex-column justify-content-center">
                <h3>My-Hospital Records</h3>
                <p>
                  My-Hospital Opens in 1998 and still remains his position same.
                </p>
                <div className="row">
                  <div className="col-md-6 d-md-flex align-items-md-stretch">
                    <div className="count-box">
                      <i className="bi bi-emoji-smile"></i>
                      <span
                        data-purecounter-start="0"
                        data-purecounter-end="65"
                        data-purecounter-duration="2"
                        className="purecounter"
                      ></span>
                      <p>
                        <strong>Happy People</strong> We always want that You
                        and Your Family remains Happy and Healthy.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 d-md-flex align-items-md-stretch">
                    <div className="count-box">
                      <i className="bi bi-journal-richtext"></i>
                      <span
                        data-purecounter-start="0"
                        data-purecounter-end="85"
                        data-purecounter-duration="2"
                        className="purecounter"
                      ></span>
                      <p>
                        <strong>Projects</strong> Now we are Making our Machine
                        more advance as soon as Possible.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 d-md-flex align-items-md-stretch">
                    <div className="count-box">
                      <i className="bi bi-clock"></i>
                      <span
                        data-purecounter-start="0"
                        data-purecounter-end="35"
                        data-purecounter-duration="4"
                        className="purecounter"
                      ></span>
                      <p>
                        <strong>24*7</strong> You can contact us Anytime.We are
                        always available for you.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 d-md-flex align-items-md-stretch">
                    <div className="count-box">
                      <i className="bi bi-award"></i>
                      <span
                        data-purecounter-start="0"
                        data-purecounter-end="20"
                        data-purecounter-duration="4"
                        className="purecounter"
                      ></span>
                      <p>
                        <strong>Awards</strong> Awarded by Health Minister of
                        India
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CEO FOUnder */}
      <section id="testimonials" className="testimonials">
        <div className="container" data-aos="zoom-in">
          <div
            className="testimonials-slider swiper"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="testimonial-item">
                  <img
                    src="https://www.shutterstock.com/image-vector/cool-beard-man-vector-logo-260nw-1719020434.jpg"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Anil Godara</h3>
                  <h4>Ceo &amp; Founder</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    We Always Want That Everyone Remains Healthy and Stay Happy
                    in their life with Their Family and Relatives.This is Our
                    Big Dream
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <img
                    src="assets/img/testimonials/testimonials-2.jpg"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Sara Wilsson</h3>
                  <h4>Designer</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Export tempor illum tamen malis malis eram quae irure esse
                    labore quem cillum quid cillum eram malis quorum velit fore
                    eram velit sunt aliqua noster fugiat irure amet legam anim
                    culpa.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <img
                    src="assets/img/testimonials/testimonials-3.jpg"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Jena Karlis</h3>
                  <h4>Store Owner</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Enim nisi quem export duis labore cillum quae magna enim
                    sint quorum nulla quem veniam duis minim tempor labore quem
                    eram duis noster aute amet eram fore quis sint minim.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <img
                    src="assets/img/testimonials/testimonials-4.jpg"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Matt Brandon</h3>
                  <h4>Freelancer</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Fugiat enim eram quae cillum dolore dolor amet nulla culpa
                    multos export minim fugiat minim velit minim dolor enim duis
                    veniam ipsum anim magna sunt elit fore quem dolore labore
                    illum veniam.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <img
                    src="assets/img/testimonials/testimonials-5.jpg"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>John Larson</h3>
                  <h4>Entrepreneur</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Quis quorum aliqua sint quem legam fore sunt eram irure
                    aliqua veniam tempor noster veniam enim culpa labore duis
                    sunt culpa nulla illum cillum fugiat legam esse veniam culpa
                    fore nisi cillum quid.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </section>

      {/* Doctor Team */}
      <section id="team" className="team">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Team</h2>
            <p>Check our Team</p>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
              <div className="member" data-aos="fade-up" data-aos-delay="100">
                <div className="member-img">
                  <img
                    src="https://thumbs.dreamstime.com/b/avatar-man-doctor-cartoon-medical-smiling-wearing-glasses-professional-medical-occupation-over-white-background-vector-79303740.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="social">
                    <a href="">
                      <i className="bi bi-twitter"></i>
                    </a>
                    <a href="">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href="">
                      <i className="bi bi-instagram"></i>
                    </a>
                    <a href="">
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </div>
                </div>
                <div className="member-info">
                  <h4>Walter</h4>
                  <span>Chief Doctor</span>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
              <div className="member" data-aos="fade-up" data-aos-delay="200">
                <div className="member-img">
                  <img
                    src="https://banner.myupchar.com/widget/avatar/doctor-avatar-male.png"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="social">
                    <a href="">
                      <i className="bi bi-twitter"></i>
                    </a>
                    <a href="">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href="">
                      <i className="bi bi-instagram"></i>
                    </a>
                    <a href="">
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </div>
                </div>
                <div className="member-info">
                  <h4> Jhonson</h4>
                  <span> Manager Doctor</span>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
              <div className="member" data-aos="fade-up" data-aos-delay="300">
                <div className="member-img">
                  <img
                    src="https://img.freepik.com/premium-vector/female-doctor-avatar-character-icon_18591-65289.jpg?w=2000"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="social">
                    <a href="">
                      <i className="bi bi-twitter"></i>
                    </a>
                    <a href="">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href="">
                      <i className="bi bi-instagram"></i>
                    </a>
                    <a href="">
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </div>
                </div>
                <div className="member-info">
                  <h4>Angel Anderson</h4>
                  <span>CTO</span>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
              <div className="member" data-aos="fade-up" data-aos-delay="400">
                <div className="member-img">
                  <img
                    src="https://thumbs.dreamstime.com/z/female-doctor-avatar-character-icon-vector-illustration-design-female-doctor-avatar-character-icon-177871105.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="social">
                    <a href="">
                      <i className="bi bi-twitter"></i>
                    </a>
                    <a href="">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href="">
                      <i className="bi bi-instagram"></i>
                    </a>
                    <a href="">
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </div>
                </div>
                <div className="member-info">
                  <h4>Amanda Jepson</h4>
                  <span>Accountant</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Us */}
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Contact</h2>
            <p>Contact Us</p>
          </div>

          <div>
            <iframe
              style={{ border: "0", width: "100%", height: "270px;" }}
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>

          <div className="row mt-5">
            <div className="col-lg-4">
              <div className="info">
                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>A108 Adam Street, New York, NY 535022</p>
                </div>

                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>info@example.com</p>
                </div>

                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <p>+1 5589 55488 55s</p>
                </div>
              </div>
            </div>

            <div className="col-lg-8 mt-5 mt-lg-0">
              <form
                action="forms/contact.php"
                method="post"
                role="form"
                className="php-email-form"
              >
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      required
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Subject"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="5"
                    placeholder="Message"
                    required
                  ></textarea>
                </div>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit">Send Message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <div className=" text-center justify-content-center align-items-center"></div>
    </div>
  );
}

export default HOCAPI(Home, "/get/dashboard");
