import React from 'react'
import Pagetitle from '../CommonHOC/Pagetitls/Pagetitle'

export default function Form() {
  return (
    <div>
      <Pagetitle title="Form"  parent='Home'parenturl='/' />

          <div className="col-lg-6">

<div className="card">
  <div className="card-body">
    <h5 className="card-title">Custom Styled Validation</h5>
    <p>For custom Bootstrap form validation messages, you’ll need to add the <code>novalidate</code> boolean attribute to your <code>&lt;form&gt;</code>. This disables the browser default feedback tooltips, but still provides access to the form validation APIs in JavaScript. </p>
 
    <form className="row g-3 needs-validation" novalidate>
      <div className="col-md-4">
        <label for="validationCustom01" className="form-label">First name</label>
        <input type="text" className="form-control" id="validationCustom01" value="John" required/>
        <div className="valid-feedback">
          Looks good!
        </div>
      </div>
      <div className="col-md-4">
        <label for="validationCustom02" className="form-label">Last name</label>
        <input type="text" className="form-control" id="validationCustom02" value="Doe" required/>
        <div className="valid-feedback">
          Looks good!
        </div>
      </div>
      <div className="col-md-4">
        <label for="validationCustomUsername" className="form-label">Username</label>
        <div className="input-group has-validation">
          <span className="input-group-text" id="inputGroupPrepend">@</span>
          <input type="text" className="form-control" id="validationCustomUsername" aria-describedby="inputGroupPrepend" required/>
          <div className="invalid-feedback">
            Please choose a username.
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <label for="validationCustom03" className="form-label">City</label>
        <input type="text" className="form-control" id="validationCustom03" required/>
        <div className="invalid-feedback">
          Please provide a valid city.
        </div>
      </div>
      <div className="col-md-3">
        <label for="validationCustom04" className="form-label">State</label>
        <select className="form-select" id="validationCustom04" required>
          <option selected disabled value="">Choose...</option>
          <option>...</option>
        </select>
        <div className="invalid-feedback">
          Please select a valid state.
        </div>
      </div>
      <div className="col-md-3">
        <label for="validationCustom05" className="form-label">Zip</label>
        <input type="text" className="form-control" id="validationCustom05" required/>
        <div className="invalid-feedback">
          Please provide a valid zip.
        </div>
      </div>
      <div className="col-12">
        <div className="form-check">
          <input className="form-check-input" type="checkbox" value="" id="invalidCheck" required/>
          <label className="form-check-label" for="invalidCheck">
            Agree to terms and conditions
          </label>
          <div className="invalid-feedback">
            You must agree before submitting.
          </div>
        </div>
      </div>
      <div className="col-12">
        <button className="btn btn-primary" type="submit">Submit form</button>
      </div>
    </form> 

  </div>
</div>

<div className="card">
  <div className="card-body">
    <h5 className="card-title">Custom Styled Validation with Tooltips</h5>
    <p>If your form layout allows it, you can swap the <code>.-feedback</code> classes for .<code>-tooltip</code> classes to display validation feedback in a styled tooltip. Be sure to have a parent with <code>position: relative</code> on it for tooltip positioning. In the example below, our column classes have this already, but your project may require an alternative setup. </p>
 
    <form className="row g-3 needs-validation" novalidate>
      <div className="col-md-4 position-relative">
        <label for="validationTooltip01" className="form-label">First name</label>
        <input type="text" className="form-control" id="validationTooltip01" value="John" required/>
        <div className="valid-tooltip">
          Looks good!
        </div>
      </div>
      <div className="col-md-4 position-relative">
        <label for="validationTooltip02" className="form-label">Last name</label>
        <input type="text" className="form-control" id="validationTooltip02" value="Doe" required/>
        <div className="valid-tooltip">
          Looks good!
        </div>
      </div>
      <div className="col-md-4 position-relative">
        <label for="validationTooltipUsername" className="form-label">Username</label>
        <div className="input-group has-validation">
          <span className="input-group-text" id="validationTooltipUsernamePrepend">@</span>
          <input type="text" className="form-control" id="validationTooltipUsername" aria-describedby="validationTooltipUsernamePrepend" required/>
          <div className="invalid-tooltip">
            Please choose a unique and valid username.
          </div>
        </div>
      </div>
      <div className="col-md-6 position-relative">
        <label for="validationTooltip03" className="form-label">City</label>
        <input type="text" className="form-control" id="validationTooltip03" required/>
        <div className="invalid-tooltip">
          Please provide a valid city.
        </div>
      </div>
      <div className="col-md-3 position-relative">
        <label for="validationTooltip04" className="form-label">State</label>
        <select className="form-select" id="validationTooltip04" required>
          <option selected disabled value="">Choose...</option>
          <option>...</option>
        </select>
        <div className="invalid-tooltip">
          Please select a valid state.
        </div>
      </div>
      <div className="col-md-3 position-relative">
        <label for="validationTooltip05" className="form-label">Zip</label>
        <input type="text" className="form-control" id="validationTooltip05" required/>
        <div className="invalid-tooltip">
          Please provide a valid zip.
        </div>
      </div>
      <div className="col-12">
        <button className="btn btn-primary" type="submit">Submit form</button>
      </div>
    </form>

  </div>
</div>

</div>
    </div>
  )
}
