import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'; 
import { Formik } from 'formik';
import HOCAPI from '../../../CommonHOC/APIHOC/HOCAPI';
import OPDList from '../OPDS/OPDList';
import Baseurl from '../../ApiComponents/Baseurl/Baseurl';
import { useContext } from 'react';
import { Provider } from '../../../Contexts/Context';

 function ServiceList(props) {
  const context=useContext(Provider);
  const closeref=useRef(null);
  useEffect(() => {
   context.GetServiceTypeList();
  }, []);
  const navigate=useNavigate();
  const [servicedata,setservicedata]=useState([]);
  const [id,setid]=useState(null);
  const [serviceTypedata, setserviceTypedata] = useState([]);

  const handledelete=(id)=>{
    // DeleteService(id).then(res=>{props.showalert("Deleted Succesfully!!","success"); Context.getservice()}).catch(err=>props.showalert("Error Occurred!!","danger"))
  }
  const seteditfunc=(id)=>{
    setid(id);
    Baseurl.get(`/service/${id}`).then(res=>{setservicedata(res.data); }).catch(err=>console.log(err)); 
  }
   
  return (
    <div className='container my-5 impo p-3'>
      <>
      <div className="modal fade" id="modalLoginForm"  tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
        aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h4 className="modal-title w-100 font-weight-bold">Update Service List</h4>
              <button required type="button" ref={closeref}  className="btn btn-outline-danger" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body mx-3">
            <Formik
        enableReinitialize
        initialValues={{
        name:servicedata.name,
        amount:servicedata.amount,
        serviceTypeId:servicedata.serviceTypeId,
        creatorid:servicedata.creatorid,
        validDay:servicedata.validDay,
        discountable:servicedata.discountable?true:false,
        hospitalid:servicedata.hospitalid,
        createdDate:servicedata.createdDate
      }}

       onSubmit={async(values,{resetForm})=>{
 const data=await JSON.stringify(values);
 props.Edit(id,data).then(res=>closeref.current.click())
       }}
       
       >
        {(
            {
               values,  
                 handleChange,
                 handleSubmit
                  
            }
        )=>(
              <form className='my-5' onSubmit={handleSubmit}>
                <label htmlFor="inputPassword1" className="form-label">Name:</label>
                <input required type="text" id="inputPassword1" name='name' value={values.name} onChange={handleChange} className="form-control" placeholder='Enter Service   Name' aria-describedby="passwordHelpBlock" />
                <br />           <label htmlFor="Amount" className="form-label">Amount:</label>
                <input required type="number" id="Amount" name='amount' value={values.amount} onChange={handleChange} className="form-control" placeholder='Enter Service Amount...' aria-describedby="passwordHelpBlock" />
                <br />
                <label htmlFor="TYpe" className="form-label">Type</label>
                <select
              required
              id="Type"
              name="Type" 
              value={values.serviceTypeId}
              onChange={handleChange}
              className="form-control"
            >
              <option>Select Service Type -:</option>
              {context.ServiceTypeList.map((items) => (
                <option key={items.id} value={items.id}>{items.name}</option>
              ))}
            </select>
                <br /> 
                <input required type="hidden" id="creatorid" name='creatorid' value={servicedata.creatorid} onChange={handleChange} className="form-control" placeholder='Enter creatorid  ...' aria-describedby="passwordHelpBlock" />
                <br />
                <label htmlFor="ValidDay" className="form-label">Valid Day:</label>
                <input required type="text" id="ValidDay" name='validDay' value={values.validDay} onChange={handleChange} className="form-control" placeholder='Enter Service ValidDay...' aria-describedby="passwordHelpBlock" />

                <br /><input  type="checkbox" className="form-check-input" value={values.discountable}  onChange={handleChange} name='discountable'  id="exampleCheck1" />
                <label className="form-check-label" htmlFor="exampleCheck1" >ISDiscountable</label>
                <br /><br />

                <button className='btn btn-primary mt-2 mx-5'  type='submit'>Save</button>


              </form>
        )}
        </Formik>
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive my-3">
        <table id="example" className="table table-striped table-bordered border-1" >
          <thead>
            <tr>
              <th >SRNO.</th>
              <th >Name</th>
              <th >Amount</th>
              <th>Type</th>
              <th>ValidDay</th>
              <th>Discountable</th>
              <th >Action</th>

            </tr>
          </thead>
          <tbody>
            {props.data.map((items, index) =>
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{items.name}</td>
                <td>{items.amount}</td>
                <td>{items.serviceTypeId}</td>
                <td>{items.validDay}</td>
                <td>{!items.discountable == 0 ? "Yes" : "No"}</td>
                <td><button className='btn btn-outline-primary mx-1' onClick={()=>seteditfunc(items.id)} data-toggle="modal" data-target="#modalLoginForm" >Edit</button> <button className='btn btn-outline-danger mx-1' onClick={()=>handledelete(items.id)}>Delete</button></td>

              </tr>
            )}
          </tbody>

        </table>
      </div></>
        <Link to='/service' className='btn btn-info'>Add Service</Link>
    </div>
  )
}

export default HOCAPI(ServiceList,"/service")
