import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Alert(props) {
  if(props.alert!=null){ toast(props.alert.message, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    className:`text-${props.alert.type}`,
    progress: 1, 
    theme: "colored",
    }); }
  return (
    <div>
      {props.alert && 
        <div > 
<ToastContainer 
position="top-center"
autoClose={5000} 
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>  
</div> 
}
    </div>
  )
}
