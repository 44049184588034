import Baseurl from "../Baseurl/Baseurl";



const EditEmployee=(id,data)=>Baseurl.patch(`/employee/update/${id}`,data);
const GetEmployee=()=>Baseurl.get("/employeeloginboth");
const AddEmployee=(data)=>Baseurl.post("/employeeloginboth",data);


export{
    EditEmployee,GetEmployee,AddEmployee
}