import Baseurl from "../Baseurl/Baseurl";


const GetDepartment=()=>Baseurl.get("/department");
const AddDepartment=(data)=>Baseurl.post("/department",data);
const EditDepartment=(id,data)=>Baseurl.patch(`/department/${id}`,data);
const GetDepartmentbyID=(id)=>Baseurl.get(`/department/${id}`);


export  {
    GetDepartment,AddDepartment,EditDepartment,GetDepartmentbyID
}