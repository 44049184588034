import React, { useEffect, useState } from 'react'
import Baseurl from '../../Components/ApiComponents/Baseurl/Baseurl';
import { useNavigate } from 'react-router-dom';
import Alert from '../../Alerts/Alert';

function HOCAPI(OriginalComp,url,payload) {
    const Newcomponent=()=>{
        const navigate=useNavigate();
        useEffect(() => {
            Default();
                        Getdata();
            
        }, []);
        const [alert, setAlert] = useState(null);
        let showalert = (message, type) => {
          setAlert({
            message: message,
            type: type
          })
          setTimeout(() => {
            setAlert(null);
          }, 3000);
        }

        
        const [data,setdata]=useState([]);
        const [getdata,setgetdata]=useState([]);
        
        const Default=async()=>{
             const token=await localStorage.getItem("token");
           Baseurl.setHeader("Authorization","Bearer"+" "+token);
           }
        // FOr Update---------------------------------------------------------------------------------------------
        const Edit=async(id,editdata)=>{ 
            const res=await Baseurl.patch(url+`/${id}`,editdata);
            if(res.ok){ Getdata(); showalert("Changes Saved Successfully!!")}
            else if(res.status==401){showalert("Please Login Again!!","danger");localStorage.clear()}
            else{showalert("Some Error Occurred!!","danger")}
            
        }


        // Get data by Id-------------------------------------------------------------------------
        const GetdatabyId=async(id)=>{ 
          const res=await Baseurl.get(url+`/${id}`);
          if(res.ok){ setgetdata(res.data)}
          else if(res.status==401){showalert("Please Login Again!!","danger");localStorage.clear()}
          else{showalert("Some Error Occurred!!","danger")}
      }

        // For Getting List-----------------------------------------------------------------------------------------------
        const Getdata=async()=>{ 
            const res=await Baseurl.get(url);
            if(res.ok){setdata(res.data)}
            else if(res.status==401){showalert("Please Login Again!!","danger");localStorage.clear()}
            else{showalert("Some Error Occurred!!","danger")}
        }


        //For Post-----------------------------------------------------------------------------------------------------------------
        const Post=async(postdata)=>{
            const res=await Baseurl.post(url,postdata);
            if(res.ok){Getdata();showalert("Added Successfully!!","success")}
            else if(res.status==401){showalert("Please Login Again!!","danger");localStorage.clear()}
            else{showalert("Some Error Occurred!!","danger")}
        }



        //For Delete-------------------------------------------------------------------------------------------------------------------
        const Delete=async(id)=>{
            const res=await Baseurl.delete(url+`/${id}`);
            if(res.ok){ Getdata()}
            else if(res.status==401){showalert("Please Login Again!!","danger");localStorage.clear()}
            else{showalert("Some Error Occurred!!","danger")}
        }
 

        return (<><OriginalComp data={data} iddata={getdata} Get={Getdata} GetbyId={GetdatabyId} Post={Post} Edit={Edit} Delete={Delete} showalert={showalert}  /> <Alert alert={alert}/></>)
    }
  return Newcomponent;
}

export default HOCAPI;
