import React, { useContext, useState } from 'react'
import { Provider } from '../../Contexts/Context'
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import Loader from '../../CommonHOC/LoaderHOC/Loader';

export default function Login() {
  const [loading, setloading] = useState(false);
  const context=useContext(Provider);
  return (
    <div>
      {!context.loading?
   <div className="container">

<section>
<div className="wrapper">
        <div className="logo">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYvBuWuTp4Ohbccktquzh6x_gaOo5Vhum68ri8Y7w&s" alt=""/>
        </div>
        <div className="text-center mt-4 name">
            MyHospital
        </div>
        <Formik
        enableReinitialize
        initialValues={{
          userName:"",password:""
        }}
        onSubmit={async(values,{resetForm})=>{
          setloading(true);
          const data={userName:values.userName,password:values.password}
          try{

            await context.login(data);
          }
          catch{
            setloading(false);

          }
          finally{

            setloading(false);
          }
        }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          })=>(
 <form className="p-3 mt-3" onSubmit={handleSubmit}>
 <div className="form-field d-flex align-items-center">
     <span className="far fa-user"></span>
     <input type="text" name="userName" id="userName" value={values.userName} onChange={handleChange} placeholder="Username" required/>
 </div>
 <div className="form-field d-flex align-items-center">
     <span className="fas fa-key"></span>
     <input type="password" name="password" id="pwd" value={values.password} onChange={handleChange} placeholder="Password" required/>
 </div>
 
 {!loading?<button type='submit' className="btn mt-3">Login</button>:<button className=' btn btn-info lds-ellipsis' disabled><div></div><div></div><div></div><div></div></button>}
</form>
          )}

        </Formik>
        <div className="text-center fs-6">
            <Link to="/forgot">Forget password?</Link>
        </div>
    </div>

</section>

</div>:<Loader/>}
    </div>
  )
}
