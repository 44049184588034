import logo from './logo.svg';
import './App.css';
import './App.scss';
import Topbar from './Layouts/Topbar';
import Sidebar from './Layouts/Sidebar';
import Profile from './Components/Profile/Profile';
import Login from './Components/Authentication/Login';
import Form from './Components/Form';
import Contact from './Components/Contact/Contact';
import Help from './Components/Contact/Help';
import Home from './Components/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom'; 
import ReactTable from "react-table"; 
import Layout from './Layouts/Layout';
import { useContext, useState } from 'react';
import { Provider } from './Contexts/Context';
import ForgetPass from './Components/Authentication/ForgetPass';
import DepartmentList from './Components/ProjectScreens/Departments/DepartmentList';
import Alert from './Alerts/Alert';
import NotFoundPage from './Components/404Page/NotFoundPage';
import ServiceType from './Components/ProjectScreens/ServicesScreen/ServiceType';
import AddDepartment from './Components/ProjectScreens/Departments/AddDepartment';
import Employee from './Components/ProjectScreens/EmployeesScreen/Employee'; 
import AddEmploye from './Components/ProjectScreens/EmployeesScreen/AddEmploye';
import Loader from './CommonHOC/LoaderHOC/Loader';
import HOCAPI from './CommonHOC/APIHOC/HOCAPI';
import Pateint from './Components/ProjectScreens/Patients/Pateint';
import OPD from './Components/ProjectScreens/OPDS/OPD';
import HospitalList from './Components/ProjectScreens/HospitalScreens/HospitalList';
import EmployeeType from './Components/ProjectScreens/EmployeesScreen/EmployeeTypes/EmployeeType';
import OpdList from './Components/ProjectScreens/OPDS/OPDList';
import ServiceList from './Components/ProjectScreens/ServicesScreen/ServiceList';
import Service from './Components/ProjectScreens/ServicesScreen/Service';


function App() {
  const [alert, setAlert] = useState(null);
  const context=useContext(Provider);
if(context.loading){return(
  <div className="bg-opacity-10 ">
    <Loader/>
   
</div>
)}
let showalert = (message, type) => {
  setAlert({
    message: message,
    type: type
  })
  setTimeout(() => {
    setAlert(null);
  }, 3000);
}
  return (
    <div className="App">
<Alert alert={alert} />    

 <Routes>
  <Route eaxct path='/login' element={<Login showalert={showalert}/>} />
  <Route exact path='/forgot' element={<ForgetPass showalert={showalert}/>} />
  <Route exact path='/' element={<Layout />} >
  <Route index element={<Home showalert={showalert}/>} />
  <Route eaxct path='/help' element={<Help />} />
  <Route eaxct path='/department/list' element={<DepartmentList showalert={showalert}/>} />
  <Route eaxct path='/department/add' element={<AddDepartment showalert={showalert}/>} />

  <Route eaxct path='/user/profile' element={<Profile showalert={showalert}/>} />
  <Route eaxct path='/service/type/list' element={<ServiceType showalert={showalert}/>} />
  <Route eaxct path='/service/list' element={<ServiceList showalert={showalert}/>} />
  <Route eaxct path='/service' element={<Service showalert={showalert}/>} />


  <Route eaxct path='/contact' element={<Contact />} />
  <Route eaxct path='/form' element={<Form showalert={showalert}/>} />
  <Route eaxct path='/employee/list' element={<Employee showalert={showalert}/>} />
  <Route eaxct path='/employee/add/:id?' element={<AddEmploye showalert={showalert}/>} />
  <Route eaxct path='/employee/type' element={<EmployeeType showalert={showalert}/>} />
  <Route eaxct path='/patient/list' element={<Pateint showalert={showalert}/>} />
  <Route eaxct path='/opd' element={<OPD showalert={showalert}/>} />
  <Route eaxct path='/opd/list' element={<OpdList showalert={showalert}/>} />
  <Route eaxct path='/hospital' element={<HospitalList showalert={showalert}/>} />
  </Route>
  <Route exact path='*' element={<NotFoundPage />}/> 
 </Routes>
    </div>
  );
}

export default App;
