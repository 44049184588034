
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react';
import { Provider } from '../../Contexts/Context';
import { Formik } from 'formik'; 
import OTPInput from "otp-input-react";
import Baseurl from '../ApiComponents/Baseurl/Baseurl';
import axios from 'axios';

// For Errors 
const SignupSchema = Yup.object().shape({
  MobileNumber: Yup.string("Please Enter a Valid Number!!").matches(/[0-9]{10}/,"Please Enter a valid Number").min(10,"Please Enter a Valid Number!!").max(10,"Please Enter a Valid Number!!").required('Please Enter Your MobileNumber!!'),
  password: Yup.string("Please Enter your Password!!").min(6,"Please Enter a Strong Password !!").required('Please Enter Your Password!!'),
  confirmPassword:Yup.string("Please Confirm Your Password!!").min(6,"Confirm Your Password!!").required("Confrim your Password!!")
});


  export default function ForgetPass(props) {

  const [MobileNumber, setMobileNumber] = useState("");
  const [sendotpdone, setsendotpdone] = useState(null);
  const [OTP, setOTP] = useState(""); 
  const [seconds, setSeconds] = useState(30);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
  
      if (seconds === 0) {
      
          clearInterval(interval);
          setsendotpdone(null)
      }
    }, 1000);
  
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const handleotp=async()=>{
    if(MobileNumber.length!=10){
  props.showalert("Please Enter Your MobileNumber!!","danger")
    }
    else{
     const res=await Baseurl.post(`https://opddemo.kmaschool.com/api/pannel/forgot/password/${MobileNumber}`);
     if(res.ok){ 
       setsendotpdone(res.data.employeeId);
       const number=MobileNumber.slice(6);
       props.showalert(`OTP sent Successfully!! to +91XXXXXXX${number}`,"success")
       setSeconds(59);
     }
     else{
       props.showalert("Please Register First!!","danger")
     }
    }
    
  }

    const navigate= useNavigate();
  return (
    <div className='wrapperforgot' style={{zIndex:"-1"}}>
  
              <div style={{backgroundColor:"white"}}>Reset Password<img width={60} height={60} src='https://img.icons8.com/clouds/512/enter-2.png'/></div>
                    <div className="p-4">
                    <Formik
      enableReinitialize
      initialValues={{
        MobileNumber:MobileNumber,
        employeeId:sendotpdone,
        password:"",
        confirmPassword:"",
        otp:parseInt(OTP)
      }}
      validationSchema={SignupSchema}
      onSubmit={async(values,{resetForm})=>{ 
        const data={
          "employeeId": values.employeeId,"otp": values.otp,"password": values.password,"confirmPassword": values.confirmPassword
        }
        if(values.employeeId==null){
          props.showalert("Please enter a Valid OTP!!","danger")
        }
        else{
        axios.post(`https://opddemo.kmaschool.com/api/pannel/setnewpassword`,data).then(res=>{navigate("/login"); props.showalert("Password Reset Successfully!!","success");}).catch(err=>props.showalert("Some error Occurred!!","danger"))  
        }
      }} 
      >
       {(
           {
               values,
                handleChange,
                handleSubmit,
                handleBlur,
                errors,
                touched
           }
       )=>(
           <form onSubmit={handleSubmit}>
                            <label htmlhtmlFor="MobileNumber" className="form-label">MobileNumber</label>
            <div className="form-field d-flex align-items-center">
                            <input type="number"  className=""  id="MobileNumber"   name='MobileNumber'
                              onBlur={handleBlur}
                              value={values.MobileNumber}
                              onChange={(e)=>setMobileNumber(e.target.value)}
                              placeholder="Enter Your Number..."
                            />
                                </div>
                              {errors.MobileNumber && touched.MobileNumber?(
                                <span p style={{color:"red",zIndex:"2"}}>{errors.MobileNumber} </span>
                                ) : null}
                                <div>
            <label className='form-label mx-2 my-1 '><b>OTP-:</b></label>
           <div className=" d-flex align-items-center">
            <OTPInput
      value={OTP}
      onChange={setOTP} 
      required
      OTPLength={6}
      inputClassName="form-field1 my-1"
      otpType="number"
      disabled={false}
    />
    {!sendotpdone?<button type='button' onClick={handleotp} className={`btn btn-secondary d-${MobileNumber.length!=10?"none":""} `}>Send OTP</button>:<button type='button' disabled className='btn btn-secondary'>{seconds}s</button>}
                              
                                  </div>
                                  </div>
    <div className='m-3'>
    <label htmlhtmlFor="password " className="form-label">Password:</label>
            <div className="form-field d-flex align-items-center ">
                            <input type="password"  className=""  id="password"   name='password'
                              onBlur={handleBlur}
                              value={values.password}
                              onChange={handleChange}
                              placeholder="Enter Your Password..."
                            />
                                </div>
                              {errors.password && touched.password?(
              <span p style={{color:"red",zIndex:"2"}}>{errors.password} </span>
           ) : null}</div>
           <div>
                       <label htmlhtmlFor="confirmPassword" className="form-label">Confirm Password</label>
            <div className="form-field d-flex align-items-center">
                            <input type="text"  className=""  id="confirmPassword"   name='confirmPassword'
                              onBlur={handleBlur}
                              value={values.confirmPassword}
                              onChange={handleChange}
                              placeholder=" confirm Your Password..."
                            />
                                </div>
                              {errors.confirmPassword && touched.confirmPassword?(
              <span p style={{color:"red",zIndex:"2"}}>{errors.confirmPassword} </span>
           ) : null}
           </div>
           {!values.password==values.confirmPassword?<p>Please Check your Password!!</p>:""}
    <br/>
                          <button type="submit" className={`btn btn-primary`} disabled={values.password!=values.confirmPassword||OTP.length!=6} >
                            Reset Password
                          </button>
                          </form>
                          

       )
       
       }
       
       </Formik> 
                      <p className="text-center mt-3">
                        Back to <Link to='/login'>Login</Link>

                      </p> 
                      </div>
</div>
  )
}
