import React, { useContext, useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Topbar from './Topbar'
import { Outlet, useNavigate } from 'react-router-dom'
import { Provider } from '../Contexts/Context'
import jwtDecode from 'jwt-decode'

export default function Layout() {
    const context=useContext(Provider); 
    const navigate=useNavigate();
   

    useEffect(() => { 
       if(context.user==null){
  navigate("/login"); 
       } 
    }, []);
  return (
      <div>
            <Sidebar/>
        <Topbar/>
         <main id="main" className="main">
            <Outlet/>
         </main>
    </div>
  )
}
