import React,{useContext, useEffect, useRef, useState} from 'react';
import { Formik, Field } from 'formik';   
import { Provider } from '../../../Contexts/Context';
import { EditServiceType, GetServiceTypeById } from '../../ApiComponents/ServiceAPis/ServiceType';
import Pagetitle from '../../../CommonHOC/Pagetitls/Pagetitle';
export default function ServiceType(props) {
// Impporting Add Service Type Function From Context
const context=useContext(Provider);
const closeref=useRef(null);
const [servicetypedata,setservicetypedata]=useState([]);
const [id,setid]=useState(null);
const [loading, setloading] = useState(false);
const handledelete=(id)=>{
  // deleteServiceType(id).then(res=>{props.showalert("Deleted Succesfully!!","success"); context.getservicetypelist()}).catch(err=>props.showalert("Error Occurred!!","danger"))
}

const seteditfunc=(id)=>{ 
setid(id); 
GetServiceTypeById(id).then(res=>{setservicetypedata(res.data)}).catch(err=>alert("error Occured!!")) 
}


useEffect(() => {
context.GetServiceTypeList();
}, []);

 
  return (
   <div className="container ">
    {context.loading==false?<>

<div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
    <div className="modal-header text-center">
              <h4 className="modal-title w-100 font-weight-bold">Update Service List</h4>
              <button required type="button" ref={closeref}  className="btn btn-outline-danger" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
      <div className="modal-body">
      <Formik
       enableReinitialize
       initialValues={{
         name:servicetypedata.name,
         isActived:servicetypedata.isActived?true:false }}

      onSubmit={async(values,{resetForm})=>{ 
        EditServiceType(id,{name:values.name,isActived:values.isActived}).then(res=>{closeref.current.click(); context.GetServiceTypeList();}).catch(err=>{props.showalert("Some Error Occured!!","danger");})
       
        }}>
        {({
          values,  
          handleChange,
                 handleSubmit
                }
        )=>(
              <form className='my-5' onSubmit={handleSubmit}>
                <label htmlFor="inputPassword1" className="form-label">Name:</label>
                <input required type="text" id="inputPassword1" name='name' value={values.name} onChange={handleChange} className="form-control" placeholder='Enter Service   Name' aria-describedby="passwordHelpBlock" />
                <br/>
                <input  type="checkbox" className="form-check-input" value={values.isActived}  onChange={handleChange} name='isActived'  id="isActived" />
                <label className="form-check-label"  htmlFor="isActived" >Active</label>
                <br/>
                <button className='btn btn-primary mt-2 mx-5' type='submit'>Save</button>
              </form>
)}
        </Formik>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>


    <div className=" row d-flex justify-content-center">
           
      <Pagetitle title='ServiceType'  />
          <div className="card-body">
    <div>
       <Formik
       enableReinitialize
       initialValues={{
        name:"" ,
       }}
       onSubmit={async(values,{resetForm})=>{ 
         context.ServiceType(values.name);
     
      }}
       
       >
        {(
            {
                values,
                 handleChange,
                 handleSubmit,
                 handleBlur,
                 errors,
                 touched
            }
        )=>(
            <form onSubmit={handleSubmit} className='my-5'>
         <label htmlFor="inputPassword5" className="form-label">Service Type:</label>
<input type="text"  name='name' value={values.name} onChange={handleChange} onBlur={handleBlur} className="form-control" placeholder='Enter Service Type Name' aria-describedby="passwordHelpBlock" required/>
                <br/>
               <button type='submit' className='btn btn-info'>Submit</button>
            </form>
        )
        
        }
        
        </Formik> 
        </div>
        </div>
        </div>
        <div className="table-responsive my-3">
                  <table  className="table table-striped table-bordered border-1"  >
    <thead>
      <tr>
        <th >SRNO.</th>
        <th >Name</th>
        <th >Active</th>
        <th >Action</th>
      </tr>
    </thead>
    <tbody>
        { context.ServiceTypeList.map((items,index)=>(
      <tr key={index}>
        <td>{index+1}</td>
        <td>{items.name}</td>
        <td>{!items.isActived == 0 ? "Yes" : "No"}</td>
        <td><button type="button" className="btn btn-primary" onClick={()=>seteditfunc(items.id)}  data-toggle="modal" data-target="#exampleModal">Edit</button> <button className='btn btn-outline-danger mx-1' onClick={()=>handledelete(items.id)}>Delete</button></td>
      </tr>
))}
    </tbody>

  </table>
</div></>:<></>}
                </div>
                
  )
}
