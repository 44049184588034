import React from 'react'
import { Link } from 'react-router-dom'

export default function Pagetitle({title,parent="Home",parenturl="/"}) {
  return (
    <div className="pagetitle">
  <h1>{title}</h1>
  <nav>
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><Link to={parenturl}>{parent}</Link></li>
      <li className="breadcrumb-item">Pages</li>
      <li className="breadcrumb-item active">{title}</li>
    </ol>
  </nav>
</div> 
  )
}
