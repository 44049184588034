import React, { useContext, useEffect } from 'react'
import { Provider } from '../../../Contexts/Context';
import Pagetitle from '../../../CommonHOC/Pagetitls/Pagetitle';
import { Link } from 'react-router-dom'; 

export default function Employee() {
    const context=useContext(Provider);
    useEffect(() => {
        context.GetEmployeeList();
    }, []);
  return (
    <div>
        <Pagetitle title="EmployeeList"  />
        <Link className='btn btn-outline-info 'to="/employee/add"  style={{float:"right"}} >Add Employee</Link>
   <table className=" table table-hover my-5">
  <thead>
    <tr>
      <th scope="col">Sr No#</th>
      <th scope="col-3">Name</th>
      <th scope="col">Mobile Number</th>
      <th scope="col"> AlternateMobile Number</th>
      <th scope="col">Email</th>
      <th scope="col">Gender</th>
      <th scope="col">DaTE OF Join</th>
      <th scope="col">DOB</th>
      <th scope="col">Type</th>

    </tr>
  </thead>
  <tbody>
    {context.employeedata.map((value,index)=>(

    <tr>
      <th scope="row">{index+1}</th>
      <td className='col-3'>{value.firstName} {value.lastName}</td>
      <td>{value.mobileNo}</td>
      <td>{value.alternateMobileNo}</td>
      <td>{value.emailId}</td>
      <td>{value.gender}</td>
      <td>{value.dataOfJoin}</td>
      <td>{value.dataOfBirth}</td>
      <td>{value.employeeType}</td>
      <td><Link  className="btn btn-outline-primary m-3"  to={`/employee/add/${value.id}`}  >Edit</Link><button className='btn btn-outline-danger mx-2' >Delete</button></td>
    </tr>
  ))}
  </tbody>
  </table>
    </div>
  )
}
