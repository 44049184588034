import React, { useContext, useRef } from 'react'
import {Formik} from 'formik'
import { Provider } from '../../../Contexts/Context' 
import Pagetitle from '../../../CommonHOC/Pagetitls/Pagetitle';
import HOCAPI from '../../../CommonHOC/APIHOC/HOCAPI';

 function AddDepartment(props) { 
  const context=useContext(Provider); 
  return(
    <div>
      <Pagetitle title="AddDepartment" parent="Home" parenturl='/' />
      <Formik
      enableReinitialize
      initialValues={{
        name:""
      }}
      onSubmit={(values)=>{
        try{
          const data={name:values.name}
       props.Post(data).then(res=>{alert("Adding");props.showalert("Added Successfully!!","success");}).catch(err=>props.showalert("Some Error Occured!!","danger"))
        }
        catch{alert("Server Down!!")}
      }}
      >
        {({values,handleChange,handleSubmit})=>(
          <form onSubmit={handleSubmit}>
            <label className='form-label'>Name:-</label>
            <input className='form-control' name='name' onChange={handleChange} value={values.name}/>
            <button type='submit' className='btn btn-primary'>Submit</button>
          </form>

        )}

      </Formik>
    </div>
  )
  
}

export default HOCAPI(AddDepartment,"/department")
