import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Pagetitle from '../../CommonHOC/Pagetitls/Pagetitle'
import { Provider } from '../../Contexts/Context'
import moment from 'moment/moment';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

export default function Profile(props) {
  const Profileedit = Yup.object().shape({
    alternateMobileNo: Yup.string("Please Enter a Valid Number!!").matches(/[0-9]{10}/,"Please Enter a valid Number").min(10,"Please Enter a Valid Number!!").max(10,"Please Enter a Valid Number!!").required('Please Enter Your MobileNumber!!'),

  });
  const Passwordedit = Yup.object().shape({
    oldPassword: Yup.string("Please Enter Your Old Password!!").required('Please Enter Your Old Password!!'),
    password: Yup.string("Please Enter Your  Password!!").required('Please Enter Your Password!!'),
    confirmpassword: Yup.string("Please Enter Confirm Your Password!!").required('Please Confirm Your Password!!'),

  });
  const  context=useContext(Provider);
  const navigate=useNavigate();
  return (
    <div>
   <Pagetitle title="Profile"  parent='Home'parenturl='/' />

    <section className="section profile">
      <div className="row">
        <div className="col-xl-4">
          

          <div className="card">
            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center"> 
              <img src="https://i.pinimg.com/474x/f1/c1/98/f1c1985141ae734194fe69fd52dcb4eb.jpg" alt="Profile" className="rounded-circle"/>
              <h2>{context.ProfileList.firstName}{context.ProfileList.lastName}</h2>
              <h3>Software Developer</h3>
              <div className="social-links mt-2">
                <Link to='/' className="twitter"><i className="bi bi-twitter"></i></Link>
                <Link to='/' className="facebook"><i className="bi bi-facebook"></i></Link>
                <Link to='/' className="instagram"><i className="bi bi-instagram"></i></Link>
                <Link to='/' className="linkedin"><i className="bi bi-linkedin"></i></Link>
              </div>
            </div>
          </div>

        </div>

        <div className="col-xl-8">

          <div className="card">
            <div className="card-body pt-3">
              
              <ul className="nav nav-tabs nav-tabs-bordered">

                <li className="nav-item">
                  <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview">Overview</button>
                </li>

                <li className="nav-item">
                  <button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit">Edit Profile</button>
                </li>

                <li className="nav-item">
                  <button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-settings">Settings</button>
                </li>

                <li className="nav-item">
                  <button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-change-password">Change Password</button>
                </li>

              </ul>
              <div className="tab-content pt-2">

{/* Profile Details Work */}
                <div className="tab-pane fade show active profile-overview" id="profile-overview">

                  <h5 className="card-title">Profile Details</h5>

                  <div className="row">
                    <div className="col-lg-3 col-md-4 label ">Full Name</div>
                    <div className="col-lg-9 col-md-8">{context.ProfileList.firstName}{context.ProfileList.lastName}</div>
                  </div>

                  <div className="row">
                    <div className="col-lg-3 col-md-4 label">Company</div>
                    <div className="col-lg-9 col-md-8">KMA Technoware</div>
                  </div>

                  <div className="row">
                    <div className="col-lg-3 col-md-4 label">JobType</div>
                    <div className="col-lg-9 col-md-8">{context.ProfileList.employeeType}</div>
                  </div>

                  <div className="row">
                    <div className="col-lg-3 col-md-4 label">Gender</div>
                    <div className="col-lg-9 col-md-8">{context.ProfileList.gender}</div>
                  </div>

                  <div className="row">
                    <div className="col-lg-3 col-md-4 label">DOB</div>
                    <div className="col-lg-9 col-md-8">{moment( context.ProfileList.dataOfBirth).format("DD-MM-YYYY")}</div>
                  </div>

                  <div className="row">
                    <div className="col-lg-3 col-md-4 label">Phone</div>
                    <div className="col-lg-9 col-md-8">{context.ProfileList.mobileNo}</div>
                  </div>

                  <div className="row">
                    <div className="col-lg-3 col-md-4 label">Email</div>
                    <div className="col-lg-9 col-md-8">{context.ProfileList.emailId}</div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 label">Alternate MobileNo.</div>
                    <div className="col-lg-9 col-md-8">{context.ProfileList.alternateMobileNo}</div>
                    {}
                  </div>

                </div>

                <div className="tab-pane fade profile-edit pt-3" id="profile-edit">
 {/* // Profile Edit Work */}
 <Formik 
   enableReinitialize 
validateOnChange
   initialValues={{
    "firstName":context.ProfileList.firstName,
  "middleName": context.ProfileList.middleName,
"lastName": context.ProfileList.lastName,
  "mobileNo":context.ProfileList.mobileNo ,
  "alternateMobileNo": context.ProfileList.alternateMobileNo,
  "emailId": context.ProfileList.emailId,
  "gender": context.ProfileList.gender,
  "employeeTypeId": 0,
  "dataOfJoin":moment( context.ProfileList.dataOfJoin).format("YYYY-MM-DD"),
  "dataOfBirth": moment(context.ProfileList.dataOfBirth).format("YYYY-MM-DD")
   }}
   validationSchema={Profileedit}
   onSubmit={async(values)=>{
const data=await JSON.stringify(values);
await context.EditEmployeeDetails(context.user,data);
   
   }}
  >
    {({
      values,
      handleChange,
      handleSubmit,
      handleBlur,errors,
      touched
    })=>(
      <form onSubmit={handleSubmit}>
                   

                   <div className="row mb-3">
                     <label for="firstName" className="col-md-4 col-lg-3 col-form-label">First Name:</label>
                     <div className="col-md-8 col-lg-9">
                       <input name="firstName" type="text" className="form-control" id="firstName" onChange={handleChange} required value={values.firstName}/>
                     </div>
                   </div>


                   <div className="row mb-3">
                     <label for="middmiddleName" className="col-md-4 col-lg-3 col-form-label">Middle Name:</label>
                     <div className="col-md-8 col-lg-9">
                       <input name="middleName" type="text" className="form-control" id="company" onChange={handleChange} required value={values.middleName}/>
                     </div>
                   </div>

                   <div className="row mb-3">
                     <label for="lastName" className="col-md-4 col-lg-3 col-form-label">Last Name:</label>
                     <div className="col-md-8 col-lg-9">
                       <input name="lastName" type="text" className="form-control" id="Job" onChange={handleChange} required value={values.lastName}/>
                     </div>
                   </div>

                   <div className="row mb-3">
                     <label for="alternateMobileNo" className="col-md-4 col-lg-3 col-form-label">Alternate MobileNo.</label>
                     <div className="col-md-8 col-lg-9">
                       <input name="alternateMobileNo" type="text" className="form-control" id="alternateMobileNo" onBlur={handleBlur} onChange={handleChange} required value={values.alternateMobileNo}/>
                     </div>
                       {errors.alternateMobileNo && touched.alternateMobileNo?(
                                <span p style={{color:"red",zIndex:"2"}}>{errors.alternateMobileNo} </span>
                                ) : null}
                   </div>

  

                   <div className="row mb-3">
                     <label for="emailId" className="col-md-4 col-lg-3 col-form-label">Email</label>
                     <div className="col-md-8 col-lg-9">
                       <input name="emailId" type="email" className="form-control" id="emailId" onChange={handleChange} required value={values.emailId}/>
                     </div>
                   </div>
                   <div className="row mb-3">
                     <label for="dataOfBirth" className="col-md-4 col-lg-3 col-form-label">DOB</label>
                     <div className="col-md-8 col-lg-9">
                       <input name="dataOfBirth" type="text" className="form-control" id="dataOfBirth" onChange={handleChange} required value={values.dataOfBirth}/>
                     </div>
                   </div>


                   <div className="text-center">
                     <button type="submit" className="btn btn-primary">Save Changes</button>
                   </div>
                 </form> 
    )}

 </Formik>
      

                </div>

                <div className="tab-pane fade pt-3" id="profile-settings"> 
                  <form>

                    <div className="row mb-3">
                      <label for="fullName" className="col-md-4 col-lg-3 col-form-label">Email Notifications</label>
                      <div className="col-md-8 col-lg-9">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" id="changesMade" checked/>
                          <label className="form-check-label" for="changesMade">
                            Changes made to your account
                          </label>
                        </div>
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" id="newProducts" checked/>
                          <label className="form-check-label" for="newProducts">
                            Information on new products and services
                          </label>
                        </div>
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" id="proOffers"/>
                          <label className="form-check-label" for="proOffers">
                            Marketing and promo offers
                          </label>
                        </div>
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" id="securityNotify" checked disabled/>
                          <label className="form-check-label" for="securityNotify">
                            Security alerts
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="text-center">
                      <button type="submit" className="btn btn-primary">Save Changes</button>
                    </div>
                  </form> 

                </div>

                <div className="tab-pane fade pt-3" id="profile-change-password">

                  {/* // Password Reset Work */}
<Formik
enableReinitialize
initialValues={
  {
    "oldPassword": "",
  "password": "",
  "confirmpassword":""
  }
}
validationSchema={Passwordedit}
onSubmit={(values)=>{
  if(values.password==values.confirmpassword){
    const data={
      oldPassword:values.oldPassword,
      password:values.password
    }
     axios.patch(`https://opddemo.kmaschool.com/api/pannel/resert/password/${context.user}`,data).then(res=>{localStorage.clear();navigate("/login");props.showalert("Password Changed Successfully!!","success");}).catch(err=>props.showalert("Some Error Occurred!!","danger"))
  }
  else{
  props.showalert("Please Confirm your Password Same!!","danger")
  }

}}
>
  {({
    values,
    handleBlur,
    handleChange,handleSubmit,
    errors,touched
  })=>(
    <form onSubmit={handleSubmit}>

    <div className="row mb-3">
      <label for="oldPassword" className="col-md-4 col-lg-3 col-form-label">Current Password</label>
      <div className="col-md-8 col-lg-9">
        <input name="oldPassword" type="password" value={values.oldPassword} onChange={handleChange} className="form-control" id="currentPassword"/>
      </div>
      {errors.oldPassword&&touched.oldPassword?<p style={{color:"red"}}>{errors.oldPassword}</p>:null}
    </div>

    <div className="row mb-3">
      <label for="password" className="col-md-4 col-lg-3 col-form-label">New Password</label>
      <div className="col-md-8 col-lg-9">
        <input name="password" type="password" value={values.password} onChange={handleChange} className="form-control" id="newPassword"/>
      </div>
      {errors.password&&touched.password?<p style={{color:"red"}}>{errors.password}</p>:null}
    </div>

    <div className="row mb-3">
      <label for="confirmpassword" className="col-md-4 col-lg-3 col-form-label">Re-enter New Password</label>
      <div className="col-md-8 col-lg-9">
        <input name="confirmpassword" type="password" className="form-control" onChange={handleChange} value={values.confirmpassword} id="renewPassword"/>
      </div>
      {errors.confirmpassword&&touched.confirmpassword?<p style={{color:"red"}}>{errors.confirmpassword}</p>:null}
    </div>

    <div className="text-center">
      <button type="submit" className="btn btn-primary">Change Password</button>
    </div>
  </form> 
  )}
</Formik>

                
                </div>

              </div> 

            </div>
          </div>

        </div>
      </div>
    </section>
    </div>
  )
}
