import React from 'react'
import { Link, useLocation } from 'react-router-dom'

export default function Sidebar() {
  const location=useLocation();
  return (
    <div className='w-25'>
        <aside id="sidebar w-25" className="sidebar">

<ul className="sidebar-nav " id="sidebar-nav">

  <li className="nav-item" id={`${location.pathname=='/'?"active":""}`}>
    <Link className={`nav-link collapsed`}  to="/">
      <i className="bi bi-grid "></i>
      <span>Home</span>
    </Link>
  </li> 
  <li className="nav-item" id={`${location.pathname=='/hospital'?"active":""}`}>
    <Link className={`nav-link collapsed`}  to="/hospital">
      <i className="bi bi-grid "></i>
      <span>Hospital</span>
    </Link>
  </li> 

  

{/* Employees */}
   <li className="nav-item">
    <a className="nav-link collapsed" data-bs-target="#components-nav1" data-bs-toggle="collapse" href="#">
      <i className=" bi-menu-button-wide"></i><span>Employees</span><i className="bi bi-chevron-down ms-auto"></i>
    </a>
    <ul id="components-nav1" className="nav-content collapse " data-bs-parent="#sidebar-nav">
    <li className="nav-item" id={`${location.pathname=='/employee/list'?"active":""}`}>
    <Link className="nav-link collapsed" to="/employee/list">
      <i className="bi bi-card-list"></i>
      <span>EmployeeList</span>
    </Link>
  </li>     


    <li className="nav-item" id={`${location.pathname=='/employee/add'?"active":""}`}>
    <Link className="nav-link collapsed" to="/employee/add">
      <i className="bi bi-card-list"></i>
      <span>Add Employee</span>
    </Link>
  </li>  

  
  <li className="nav-item" id={`${location.pathname=='/employee/type'?"active":""}`}>
    <Link className="nav-link collapsed" to="/employee/type">
      <i className="bi bi-card-list"></i>
      <span>EmployeeType</span>
    </Link>
  </li>      
    </ul>
  </li>  


{/* Services */}
  <li className="nav-item">
    <a className="nav-link collapsed" data-bs-target="#components-nav2" data-bs-toggle="collapse" href="#">
      <i className="bi bi-menu-button-wide"></i><span>Services</span><i className="bi bi-chevron-down ms-auto"></i>
    </a>
    <ul id="components-nav2" className="nav-content collapse " data-bs-parent="#sidebar-nav">
    <li className="nav-item" id={`${location.pathname=='/service/type/list'?"active":""}`}>
    <Link className="nav-link collapsed" to="/service/type/list">
      <i className="bi bi-card-list"></i>
      <span>ServiceType</span>
    </Link>
  </li> 
    <li className="nav-item" id={`${location.pathname=='/service/list'?"active":""}`}>
    <Link className="nav-link collapsed" to="/service/list">
      <i className="bi bi-card-list"></i>
      <span>Service List</span>
    </Link>
  </li> 
    </ul>
  </li>  


{/* Patient OPD */}
  <li className="nav-item">
    <a className="nav-link collapsed" data-bs-target="#components-nav3" data-bs-toggle="collapse" href="#">
      <i className="bi bi-menu-button-wide"></i><span>OPD/Patient</span><i className="bi bi-chevron-down ms-auto"></i>
    </a>
    <ul id="components-nav3" className="nav-content collapse " data-bs-parent="#sidebar-nav">
 
  <li className="nav-item" id={`${location.pathname=='/patient/list'?"active":""}`}>
    <Link className="nav-link collapsed"  to='/patient/list'>
      <i className="bi bi-envelope"></i>
      <span>Patient</span>
    </Link>
  </li> 
  <li className="nav-item" id={`${location.pathname=='/opd'?"active":""}`}>
    <Link className="nav-link collapsed"  to='/opd'>
      <i className="bi bi-envelope"></i>
      <span>OPD</span>
    </Link>
  </li> 
  <li className="nav-item" id={`${location.pathname=='/opd/list'?"active":""}`}>
    <Link className="nav-link collapsed"  to='/opd/list'>
      <i className="bi bi-envelope"></i>
      <span>OPD List </span>
    </Link>
  </li> 
    </ul>
  </li>  



 {/* Department */}
  <li className="nav-item">
    <a className="nav-link collapsed" data-bs-target="#components-nav4" data-bs-toggle="collapse" href="#">
      <i className="bi bi-menu-button-wide"></i><span>Departments</span><i className="bi bi-chevron-down ms-auto"></i>
    </a>
    <ul id="components-nav4" className="nav-content collapse " data-bs-parent="#sidebar-nav">
   
  <li className="nav-item" id={`${location.pathname=='/department/list'?"active":""}`}>
    <Link className="nav-link collapsed" to="/department/list">
      <i className="bi bi-card-list"></i>
      <span>DepartmentList</span>
    </Link>
  </li> 
    </ul>
  </li>  

 
  <li className="nav-item" id={`${location.pathname=='/help'?"active":""}`}>
    <Link className="nav-link collapsed"  to='/help'>
      <i className="bi bi-question-circle"></i>
      <span>F.A.Q</span>
    </Link>
  </li> 
 
 
  <li className="nav-item" id={`${location.pathname=='/contact'?"active":""}`}>
    <Link className="nav-link collapsed"  to='/contact'>
      <i className="bi bi-envelope"></i>
      <span>Contact</span>
    </Link>
  </li> 
 

</ul>

</aside>
    </div>
  )
}
