import React,{useState} from 'react';
import { Formik, Field } from 'formik';
import { Link, useNavigate } from 'react-router-dom'
import { useContext } from 'react'; 
import Baseurl from '../../ApiComponents/Baseurl/Baseurl'
import * as yup from 'yup'
export default function OPD(props) {
 const validation=yup.object({
  invoiceNumber:yup.number().max(5,"Please Enter invoice number less then 5 Number").required("InvoiceNumber is Required")
 })
 
 const [loading, setloading] = useState(false);
 
 
  return (
    <div>
    <div >
          <div className="card-header"><h1>Hospital OPD<img width={40} height={40} src='https://img.icons8.com/emoji/512/hospital-emoji.png'/></h1></div>
    <div>
       <Formik
       enableReinitialize
       initialValues={{
        name: "",
        gender:"Male",
        dob: "",
        maritalStatus: "",
        mobileNo:"" ,
        email: "",
        address1:"",
        address2:"",
        city: "",
        state:"",
        pincode:"" ,
        date: "",
        validuptoDate: "",
        invoiceNumber:"",
        dayNumber:"" , 
        doctorId:"" ,
        opratorId:"" ,
        description: "",
        weight: "",
        bp: "",
        temp: "",
        amount: "",
        discount:""
       }}
       validationSchema={validation}
       onSubmit={async(values,{resetForm})=>{
         setloading(true); 
         console.log(values)
        const data=await JSON.stringify(values);
        Baseurl.post("/patient/opd",data).then((result) => {
          alert("Done ")
        }).catch((err) => {
          alert("Error")
        });
   setloading(false);
     
       }}
       
       >
        {(
            {
                values,
                 handleChange,
                 handleSubmit,
                 handleBlur,
                 errors,
                 touched
            }
        )=>(
            <form onSubmit={handleSubmit}>
               <div className="row mb-3">
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example1">Name:</label>
              <input type="text" id="form6Example1" name='name' value={values.name} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example2">Doctor:</label>
              <input type="number" id="form6Example2" name='doctorId' value={values.doctorId} onChange={handleChange} onBlur={handleBlur}  className="form-control"  required/>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example3">Address1:</label>
              <input type="text" id="form6Example3" name='address1' value={values.address1} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example4">Address2:</label>
              <input type="text" id="form6Example4" name='address2'  value={values.address2} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example3">City:</label>
              <input type="text" id="form6Example3" name='city' value={values.city} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example4">State:</label>
              <input type="text" id="form6Example4" name='state'  value={values.state} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example3">Pincode:</label>
              <input type="number" id="form6Example3" name='pincode' value={values.pincode} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example4">MobileNumber:</label>
              <input type="number" id="form6Example4" name='mobileNo'  value={values.mobileNo} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
          </div>
        </div>


        <div className="row mb-3">
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example5">DOB:</label>
              <input type="date" id="form6Example5" name='dob' value={values.dob} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example6">MaritalStatus:</label>
   <select value={values.maritalStatus} onChange={handleChange} name="maritalStatus" className='form-control'>
    <option>Choose One-:</option>
    <option value="Married">Married</option>
    <option value="UnMarried">UnMarried</option>
   </select>
            </div>
          </div>
        </div>


        <div className="row mb-3">
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example7">Description:</label>
              <input type="text" id="form6Example7" name='description' value={values.description} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example8">Gender:</label>
            <br/>
            <input type='radio' className='mx-2' name='Gender'htmlFor='Gender' value="Male" onChange={handleChange}  />Male
          <input type='radio' name='Gender' className='mx-2' htmlFor='Gender'  value="Female" onChange={handleChange}  />Female
          <input type='radio' name='Gender' className='mx-2' htmlFor='Gender'  value="Others" onChange={handleChange}    />Others
            </div>
          </div> 
        </div>
        <div className="row mb-3">
      
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example9">BP:</label>
              <input type="number" id="form6Example9" name='bp' value={values.bp} onChange={handleChange} onBlur={handleBlur} className="form-control"  required/>
            </div>

          </div>
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example10">Weight:</label>
              <input type="number" id="form6Example10" name='weight' value={values.weight} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
          </div>
        </div>
        <div className="row mb-3">
      
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example11">Date:</label>
              <input type="date" id="form6Example11" name='date' value={values.date} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example12">Email:</label>
              <input type="email" id="form6Example12" name='email' value={values.email} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
          </div>
             </div >
             <div className="row mb-3">
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example13">Temp[Celcius]:</label>
              <input type="number" id="form6Example13" name='temp' value={values.temp} onChange={handleChange} onBlur={handleBlur} className="form-control"  required/>
            </div>
          </div> 
          <div className="col">
          <div className="form-outline">
            <label className="form-label" htmlFor="form6Example13">Operator Id:</label>
              <input type="number" id="form6Example13" name='opratorId' value={values.opratorId} onChange={handleChange} onBlur={handleBlur} className="form-control"  required/>
            </div>
          </div>
          </div>
          <div className="row mb-3">
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example15">DayNumber:</label>
              <input type="number" id="form6Example15" name='dayNumber' value={values.dayNumber} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
          </div> 
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example16">Invoice Number:</label>
              <input type="number" id="form6Example16" name='invoiceNumber' value={values.invoiceNumber} onChange={handleChange} onBlur={handleBlur} className="form-control" required />

            </div>
            {errors.invoiceNumber&&touched.invoiceNumber?(<p className='text-danger'>{errors.invoiceNumber}</p>):null}
          </div>
          </div>
          <div className="row mb-3">
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example17">ValidUpTO:</label>
              <input type="date" id="form6Example17" name='validuptoDate' value={values.validuptoDate} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
          </div> 
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example18">Amount:</label>
              <input type="number" id="form6Example18" name='amount' value={values.amount} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example18">Discount:</label>
              <input type="number" id="form6Example18" name='discount' value={values.discount} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
            </div>
          </div>
                <br/>
                {loading==false?<button type='submit' className='btn btn-info col-8'>Submit</button>:<button className=' btn btn-info lds-ellipsis' disabled><div></div><div></div><div></div><div></div></button>}              
                
               
                
            </form>
        )
        
        }
        
        </Formik> 
        </div>
        </div>
 
 
                </div>
  )
        }