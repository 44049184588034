import React from 'react'
import { Link } from 'react-router-dom'
import Pagetitle from '../../CommonHOC/Pagetitls/Pagetitle'

export default function Help() {
  return (
    <div> 

<Pagetitle title="Help"  parent='Home'parenturl='/' />

<section className="section faq">
  <div className="row">
    <div className="col-lg-6">

      <div className="card basic">
        <div className="card-body">
          <h5 className="card-title">Basic Questions</h5>

          <div>
            <h6>Who's the doctor in charge of me? </h6>
            <p>You'll be cared for by a team of doctors and nurses, not your family doctor. The attending physician is your "go-to" doctor who understands the big picture and who discharges you from the hospital.</p>
          </div>

          <div className="pt-2">
            <h6>2. What's my problem?</h6>
            <p> The attending physician should explain things so you can understand your diagnosis and care plan. Never feel shy to ask the doctor to repeat the explanation AND to include a family member for another pair of ears. When a diagnosis is not made right away, keep asking.</p>
          </div>

          <div className="pt-2">
            <h6>3. Can I refuse a test or a doctor to see me?</h6>
            <p> Yes. Often a patient declines a test, operation, or a doctor because of unclear information. A conversation with the attending physician is helpful, knowing you can still decline.</p>
          </div>

        </div>
      </div>
 
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Your Doubts</h5>

          <div className="accordion accordion-flush" id="faq-group-1">

            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-target="#faqsOne-1" type="button" data-bs-toggle="collapse">
                UWhat changes in medications do I have to know about?
                </button>
              </h2>
              <div id="faqsOne-1" className="accordion-collapse collapse" data-bs-parent="#faq-group-1">
                <div className="accordion-body">
                  Medication changes often cause the greatest confusion, which is why keeping a diary is helpful. It’s important to understand the final choices. Doses will be given to you in writing when you’re discharged, and the list will be sent to your primary doctor and everyone involved in your post-hospital care.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-target="#faqsOne-2" type="button" data-bs-toggle="collapse">
                What is my out-of-pocket cost for being in the hospital?
                </button>
              </h2>
              <div id="faqsOne-2" className="accordion-collapse collapse" data-bs-parent="#faq-group-1">
                <div className="accordion-body">
                 A question your doctor is unlikely to be able to answer, but one that your insurance company can because the cost varies depending on your insurance. Call your insurance plan or ask a family member to do so. Doctors are interested and sensitive to this issue and many times are able to find creative ways to save costs.
                 </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-target="#faqsOne-3" type="button" data-bs-toggle="collapse">
                Am I being observed or admitted?
                </button>
              </h2>
              <div id="faqsOne-3" className="accordion-collapse collapse" data-bs-parent="#faq-group-1">
                <div className="accordion-body">
               A product of Healthcare Reform, "observation status" means you're expected to be discharged within 24 hours. Many insurers, including Medicare, regard observation status as Part B or outpatient services. If you’re unexpectedly in the hospital for more than 24 hours, you’re usually changed to full admission status because observation status would cost you more.
                 </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-target="#faqsOne-4" type="button" data-bs-toggle="collapse">
                What must I look out for after being discharged?
                </button>
              </h2>
              <div id="faqsOne-4" className="accordion-collapse collapse" data-bs-parent="#faq-group-1">
                <div className="accordion-body">
                You'll get specific instructions on symptoms such as fever or pain, which would be alarming enough to call your family doctor or go to the emergency room. Your attending physician will make every effort to provide your primary-care doctor with details of your hospital stay so he or she will be able to handle the majority of concerns. Ideally, immediately upon discharge, your primary-care doctor becomes the team leader.
                 </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-target="#faqsOne-5" type="button" data-bs-toggle="collapse">
                What are my options?
                </button>
              </h2>
              <div id="faqsOne-5" className="accordion-collapse collapse" data-bs-parent="#faq-group-1">
                <div className="accordion-body">
If surgery is recommended, understand why you need it and what it entails. Check who will perform the surgery, kind of anesthesia needed and all possible complications involved. Ask about alternatives to surgery, and options for a second opinion
                 </div>
              </div>
            </div>

          </div>

        </div>
      </div> 

    </div>

    <div className="col-lg-6"> 
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Your Questions</h5>

          <div className="accordion accordion-flush" id="faq-group-2">

            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-target="#faqsTwo-1" type="button" data-bs-toggle="collapse">
                What Are Your Medical and Surgical Histories?
                </button>
              </h2>
              <div id="faqsTwo-1" className="accordion-collapse collapse" data-bs-parent="#faq-group-2">
                <div className="accordion-body">
The patient health record will be more complete and valuable if you know whether they have ever been hospitalized, treated for a chronic condition, had medical tests, or had surgery. Even if an adult patient had surgery or some other treatment as a child, it's important to know about it when creating a treatment plan and delivering healthcare.
                 </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-target="#faqsTwo-2" type="button" data-bs-toggle="collapse">
                What are the treatment options for my condition?
                </button>
              </h2>
              <div id="faqsTwo-2" className="accordion-collapse collapse" data-bs-parent="#faq-group-2">
                <div className="accordion-body">
Some conditions or injuries can be treated only surgically but others may be cured with just medication alone. Make sure you ask your doctor about all the treatment options available as well as their pros and cons. There may be one or multiple possible approaches to treat the same disease. Knowing what they are will help put things in perspective. It will also be easier for you to make an informed decision.
                 </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-target="#faqsTwo-3" type="button" data-bs-toggle="collapse">
                What is the short- and long-term prognosis?
                </button>
              </h2>
              <div id="faqsTwo-3" className="accordion-collapse collapse" data-bs-parent="#faq-group-2">
                <div className="accordion-body">
                
It is not always possible to predict how you are going to be affected by your disease. However, a hospital doctor should be able to give you a general overview of the condition. And, he or she should be able to describe some of the different scenarios you may be facing.

If your disease is something that isn’t going away and has become chronic, you probably want to ask for a list of local support groups that can help you to cope with it.
                </div>
              </div>
            </div>


            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-target="#faqsTwo-5" type="button" data-bs-toggle="collapse">
                How will this affect my home and work life?
                </button>
              </h2>
              <div id="faqsTwo-5" className="accordion-collapse collapse" data-bs-parent="#faq-group-2">
                <div className="accordion-body">
Asking your hospital doctor this question will help you to understand more about what your life will be like once you’re discharged. For example, you may need to be on light duty if your work is physically demanding. Or, you may need to avoid driving for some time.
                 </div>
              </div>
            </div>

          </div>

        </div>
      </div> 

      <div className="card">
        <div className="card-body">
          <h5 className="card-title">After DisCharge</h5>

          <div className="accordion accordion-flush" id="faq-group-3">

            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-target="#faqsThree-1" type="button" data-bs-toggle="collapse">
                What follow-up care will I need after my discharge?
                </button>
              </h2>
              <div id="faqsThree-1" className="accordion-collapse collapse" data-bs-parent="#faq-group-3">
                <div className="accordion-body">
Another important point is to ask what doctors you need to follow up with after the discharge and how soon you need to see them. Do not hesitate to request the appointments to be made for you to have a smooth transition to outpatient care.
                 </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-target="#faqsThree-2" type="button" data-bs-toggle="collapse">
                Do I need any kind of care after my hospital stay?
                </button>
              </h2>
              <div id="faqsThree-2" className="accordion-collapse collapse" data-bs-parent="#faq-group-3">
                <div className="accordion-body">
                
A red flag that you may need care at home after your hospitalization is if you have not walked or if you are still feeling weak and wonder, “Am I strong enough to go home?”

Even though you may appear alert and strong in the hospital, your medical team needs to assess if you need transition care and at-home care services. Communication and coordination between the hospital and family or next facility is key. Will you be homebound? Will you need home healthcare to help with a medical condition or doctor-directed therapy?

The hospital team looks at a number of factors before your discharge home or to another care facility. Your medical team will evaluate what you can do in the hospital to ensure that you can do similar activities at home. How far can you walk? Can you get in and out of the bathroom and shower? Can you walk up and down the steps? If you have chronic obstructive pulmonary disease (COPD), your physician may put you on oxygen support. Your activity level can slow down and you might get tired doing certain things. The hospital staff will look at how you are moving around in the hospital and consider if you need assistance at home.                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-target="#faqsThree-3" type="button" data-bs-toggle="collapse">
                What activities am I allowed to do or what activities should I avoid?
                </button>
              </h2>
              <div id="faqsThree-3" className="accordion-collapse collapse" data-bs-parent="#faq-group-3">
                <div className="accordion-body">
              
A few days in advance of a hospital discharge, nurses and discharge planners may assess your ability to walk and any progress you have made with physical or occupational therapy — can you safely ascend the steps to get into your house or stairs inside the house? Can you get in and out of the bathtub at home? What can be done to prevent falls? Can you fix your own meals? Can you reach to wash and dry your back? If you have had a recent surgery, can you put on your own socks? Do you need assistance with any other activities of daily living?

Understanding any activity limitations before leaving the hospital will help you prepare for any in-home care you may need to help ensure the best recovery.                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-target="#faqsThree-4" type="button" data-bs-toggle="collapse">
                What medical equipment will I need at home?
                </button>
              </h2>
              <div id="faqsThree-4" className="accordion-collapse collapse" data-bs-parent="#faq-group-3">
                <div className="accordion-body">
             
It’s been said that for every day a person is in the hospital, they get one day weaker. If you have been on weeks of bed rest, it can take some time to regain strength at home. Will you need a cane, walker or wheelchair? Have you practiced in the hospital with these assistive devices? If you are sent home with a nebulizer or infusion tubing, do you know how to use the product and keep it clean to prevent infection? Be sure to practice with your at-home medical equipment and supplies in the hospital before discharge.                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-target="#faqsThree-5" type="button" data-bs-toggle="collapse">
                Do I have follow-up medical appointments, and when are those?
                </button>
              </h2>
              <div id="faqsThree-5" className="accordion-collapse collapse" data-bs-parent="#faq-group-3">
                <div className="accordion-body">
                
Before you go home from the hospital, it’s important to know what follow-up appointments will be needed. You may need to see your general practitioner, a new or existing specialist, or a physical therapist. Understand if appointments have already been scheduled, or if you will need to coordinate the appointments. Be sure to get the contact information for any doctor responsible for your follow-up care.                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

    </div>

  </div>
</section>


    </div>
  )
}
