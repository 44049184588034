import React, { useContext, useEffect, useState } from "react";
import { Formik, Field } from "formik";
import { Link, useNavigate } from "react-router-dom"; 
import axios from "axios"; 
import { Provider } from "../../../Contexts/Context";
import HOCAPI from "../../../CommonHOC/APIHOC/HOCAPI";
 function Service(props) { 
const context=useContext(Provider);
  const [loading, setloading] = useState(false); 
  const navigate = useNavigate();
   
  useEffect(()=>{
    context.GetServiceTypeList();
  },[])

  return (
    <div className="container pt-2 impo mt-5">
      <Formik
        enableReinitialize
        initialValues={{
          Name: "",
          Amount: "",
          Type: "",
          ValidDay: "",
          creatorid:context.user,
          Check: "" ? true : false,
        }}
        onSubmit={async (values, { resetForm }) => {
            const data={
                name:values.Name,
                amount:values.Amount, 
                validday:values.ValidDay,
                creatorid:values.creatorid,  
  discountable: values.Check,
  serviceTypeId: values.Type,
  createdDate: new Date,
  hospitalid: 1

            } 
            setloading(true);
            props.Post(data);
            setloading(false);
            resetForm();
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
        }) => (
          <form onSubmit={handleSubmit} className="my-5">
            <label htmlFor="inputPassword1" className="form-label">
              Name:
            </label>
            <input
              required type="text"
              id="inputPassword1"
              name="Name"
              value={values.Name}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-control"
              placeholder="Enter Service   Name"
              aria-describedby="passwordHelpBlock"
            />
            <br />{" "}
            <label htmlFor="Amount" className="form-label">
              Amount:
            </label>
            <input
              required type="number"
              id="Amount"
              name="Amount"
              value={values.Amount}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-control"
              placeholder="Enter Service Amount..."
              aria-describedby="passwordHelpBlock"
            />
            <br />
            <label htmlFor="TYpe" className="form-label">
              Type
            </label>
            <select
              required
              id="Type"
              name="Type"
              value={values.Type}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-control"
            >
              <option>Select Service Type -:</option>
              {context.ServiceTypeList.map((items) => (
                <option key={items.id} value={items.id}>{items.name}</option>
              ))}
            </select>
            <br />
            
            <label htmlFor="ValidDay" className="form-label">
              Valid Day:
            </label>
            <input
              required type="text"
              id="ValidDay"
              name="ValidDay"
              value={values.ValidDay}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-control"
              placeholder="Enter Service ValidDay..."
              aria-describedby="passwordHelpBlock"
            />
            <br />
            <input
               type="checkbox"
              className="form-check-input"
              value={values.discountable}
              onChange={handleChange}
              name="discountable" 
              id="exampleCheck1"
            />
            <label className="form-check-label" htmlFor="exampleCheck1">
              ISDiscountable
            </label>
            <br />
            <br />
            {loading == false ? (
              <button  type="submit" className="btn btn-info">
                Submit
              </button>
            ) : (
              <button className=" btn btn-info lds-ellipsis" disabled>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </button>
            )}{" "}
          </form>
        )}
      </Formik>
      <Link
        className="btn btn-info my-3" 
        to="/service/list"
      >
        Go To Service List
      </Link>
    </div>
  );
}

export default HOCAPI(Service,"/service")
