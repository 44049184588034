import React, { Component, createContext } from 'react'
import LoginApi from '../Components/ApiComponents/AuthenticationAPi/LoginApi';
import jwtDecode from 'jwt-decode';  
import Departmentapi, { AddDepartment, GetDepartment } from '../Components/ApiComponents/DepartmentApi.js/Departmentapi';
import { GetProfiledetail } from '../Components/ApiComponents/ProfileApiEmployee/GetProfile';
import { AddServiceType, GetServiceType } from '../Components/ApiComponents/ServiceAPis/ServiceType'; 
import { EditEmployee, GetEmployee } from '../Components/ApiComponents/EmployeeApi/EmployeesApi'; 
import Baseurl from '../Components/ApiComponents/Baseurl/Baseurl';

const Provider=createContext();
 class Context extends Component {
    constructor(props){
        super(props);
        this.state={
            loading:true,
            user:null,
            DepartmentList:[],
            ServiceTypeList:[],
            ProfileList:[],
            employeedata:[]
        }
    }

// Get the Employee List
GetEmployeeList=async()=>{
  const res=await GetEmployee();
  this.setState({loading:true})
  if(res.ok)
  { 
    this.setState({
      employeedata:res.data,
      loading:false
    });
  }
  else{
    this.props.showalert("Some Error Ocuured!!","danger")
  }
}


// For Logout Function
handlelogout=()=>{
  this.setState({loading:true})
  localStorage.clear();
  this.setState({user:null,loading:false});
  this.props.navigate("/login")
  this.props.showalert("Logout Successfully!!","success");
}


GetProfile=async(id)=>{
  const res=await GetProfiledetail(id);
  if(res.ok){
   this.setState({ProfileList:res.data});
  }
  else{
    this.props.showalert("Some Error Occurred!!","danger");
  }
}

default=async()=>{
  const token=await localStorage.getItem("token");
Baseurl.setHeader("Authorization","Bearer"+" "+token);
}
 
// Get Department List 
getdepartmentlist=async()=>{
 const response=await GetDepartment();
 if(response.ok){
 this.setState({
  DepartmentList:response.data
 })
 }
 else{
  this.props.showalert("Some Error Occurred!!","danger");
 }
}
 
// Add Department
 AddDepartmentt=async(data)=>{
  const newdata={name:data};
  const res= await AddDepartment(newdata);
  if(res.ok){
    this.props.navigate("/department/list");
    this.props.showalert("Department Added Successfully!!");
  }
  else{
    alert("Department NOt added!!");
  }
 }


// For setting state in the user 
     decodetoken=(token)=>{
        const decode=jwtDecode(token);
        if(decode.id){
         this.GetProfile(decode.id);
          this.setState({user:decode.id})
        }
        else{alert("Decode Token Failed")}
    }


    // // Check Login Function 
    defaultfunction=async()=>{
      const token=   localStorage.getItem("token"); 
      this.setState({loading:true})
      if(token){
        try{await this.decodetoken(token);}
        catch{this.setState({user:null});localStorage.clear();  this.props.showalert("Please Provide Valid Details!!","danger");}
      }
      this.setState({loading:false})
    }

// Login Function
     login=async(data)=>{
       this.setState({loading:true})
        const resp=await LoginApi(data);
        if(resp.status==200){
          localStorage.setItem("token",resp.data.token) 
          this.setState({loading:false})
            this.props.navigate("/");
            this.decodetoken(resp.data.token);
            
        }
        else{this.props.showalert("Please Login With Right Credentials!!","danger");this.setState({loading:false})}
     }


     // Get Service Type List 
     GetServiceTypeList=async()=>{
      const response=await GetServiceType();
      if(response.ok){
         this.setState({ServiceTypeList:response.data});
      }
      else{
        this.props.showalert("Some Error Occurred!!","danger");
      }

     }
// Add Service Type
ServiceType=async(name)=>{
  const res=await AddServiceType({name});
  if(res.ok){
   this.GetServiceTypeList();
   this.props.showalert("Added Successfully!!","success");
  }
  else{  this.props.showalert("Some Error Occurred!!","danger");}

}

    componentDidMount(){
        this.defaultfunction(); 
        this.default();
      
    }



    // Edit the Employee Details
    EditEmployeeDetails=async(id,data)=>{
      const response=await EditEmployee(id,data);
      if(response.ok){
        this.props.showalert("Details Updated Successfully!!!","success");
      }
      else{
        this.props.showalert("Some Error Occurred!!","danger");
      }

    }
  render() {
    return (
      <div>
        <Provider.Provider value={{
            ...this.state,
            login:this.login,
            handlelogout:this.handlelogout,
            getdepartmentlist:this.getdepartmentlist,
            GetServiceTypeList:this.GetServiceTypeList,
            ServiceType:this.ServiceType,
            EditEmployeeDetails:this.EditEmployeeDetails,
            AddDepartmentt:this.AddDepartmentt,
            GetEmployeeList:this.GetEmployeeList
        }}>
            {this.props.children}
        </Provider.Provider>
      </div>
    )
  }
}


 

export {
    Context,Provider
}
