import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Baseurl from '../../ApiComponents/Baseurl/Baseurl';
export default function HospitalList() {
    const [data, setdata] = useState([]);
    const getdata=()=>{
        Baseurl.get("/Hosptal").then(res=>{setdata(res.data)}).catch(err=>alert(err));
    }
    useEffect(() => {
        getdata();
    }, []);
  return (
    <div>
        <h1>My <i>Hospital</i></h1>
        <div className='table-responsive my-5'>

     <table className='table-hover table table-bordered'>
            <thead>
                <th>Name</th>
                <th>Logo</th>
                <th>Register Number</th>
                <th>Contact Number</th>
                <th>Website</th>
                <th>Email</th>
                <th>Address1</th>
                <th>Address2</th>
                <th>City</th>
                <th>State</th>
                <th>Pincode</th>
                <th>Longitude</th>
                <th>Latitude</th> 
            </thead>
            <tbody>
                {data.map((values,index)=>(
                    <tr>
                         <td>{values.name}</td>
                <td>{values.logo}</td>
                <td>{values.resgisterNumber}</td>
                <td>{values.contactNumber}</td>
                <td>{values.website}</td>
                <td>{values.mailId}</td>
                <td>{values.address1}</td>
                <td>{values.address2}</td>
                <td>{values.city}</td>
                <td>{values.state}</td>
                <td>{values.pinCode}</td>
                <td>{values.logitude}</td>
                <td>{values.latitude}</td> 
                    </tr>
                ))}
            </tbody>
            
        </table> 
                </div>
        
    </div>
  )
}
