import React, { useContext, useEffect, useRef, useState } from 'react'

import axios from 'axios';
import { Formik } from 'formik';
import {  useNavigate } from 'react-router-dom'
import Baseurl from '../../ApiComponents/Baseurl/Baseurl';
import HOCAPI from '../../../CommonHOC/APIHOC/HOCAPI';

 function OpdList(props) {
  const showmodal=useRef(); 
  const closemodal=useRef(); 
  const closeref=useRef();
  const [editdata, seteditdata] = useState([]);
  const [id, setid] = useState(null);
  const [loading, setloading] = useState(false); 
  const navigate=useNavigate();

  const [maindata, setmaindata] = useState([]);
 

  const handleeditopddetails=()=>{ 
      props.GetbyId(id);
    closeref.current.click();
    showmodal.current.click();
  }


  const handleeditpatientdetails=()=>{ 
    closeref.current.click();
  navigate('/patient/list')
  }

  
  return (
    <div >
  {/* Choose Option */}
  
<div className="modal fade" id="exampleModal"   tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Choose Your Option</h5>
        <button type="button btn-outline-danger" className="close" data-dismiss="modal" aria-label="Close">
        &times;
        </button>
      </div>
      <div className="modal-body">
         <button className='btn btn-outline-info' onClick={handleeditopddetails}  >OPD Details Edit</button>
         <button className='btn btn-outline-success mx-3' onClick={handleeditpatientdetails}   >Patient Details Edit</button>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary"   data-dismiss="modal" ref={closeref} >Close</button> 
      </div>
    </div>
  </div>
</div>



{/* OPD Details Edit */}

       <button className='btn btn-outline-primary mx-1'  ref={showmodal} hidden data-toggle="modal" data-target="#modalLoginForm1" >Edit</button>
<div className="modal fade" id="modalLoginForm1"  tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
        aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h4 className="modal-title w-100 font-weight-bold">Update PatientOPD Details</h4>
              <button required type="button" ref={closemodal}   className="btn btn-outline-danger" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body mx-3">
            <Formik
       enableReinitialize
       initialValues={{
         Doctor: props.iddata.doctorId,
          Service:   props.iddata.description,
          BP:   props.iddata.bp,
          Weight:  props.iddata.weight,
          Date:   props.iddata.date,
          Temp:   props.iddata.temp,
          DayNumber:   props.iddata.dayNumber,
          Invoice:   props.iddata.invoiceNumber,
          ValidUpTO:  props.iddata.validuptoDate,
          Amount:   props.iddata.amount,
          discount: props.iddata.discount,
          opratorId: props.iddata.opratorId,
          patientId: props.iddata.patientId
       }}
       onSubmit={async(values,{resetForm})=>{
        setloading(true);
         const data={"date": values.Date,"validuptoDate": values.ValidUpTO,"invoiceNumber": values.Invoice,"dayNumber": values.DayNumber,"patientId": values.patientId,"doctorId": values.Doctor,"opratorId": values.opratorId,"description": values.Service,"weight": values.Weight,"bp":values.BP ,"temp": values.Temp,"amount": values.Amount,"discount": values.discount }
        Baseurl.patch(`/opd/${id}`,data).then(res=>{props.Get();closemodal.current.click();props.showalert("Saved Successfully!!!","success");}).catch(err=>{closemodal.current.click();props.showalert("Error Occurred!!","danger")})
   setloading(false);
     resetForm();
       }}
       
       >
        {(
            {
                values,
                 handleChange,
                 handleSubmit,
                 handleBlur,
                 errors,
                 touched
            }
        )=>(
            <form onSubmit={handleSubmit}>


        <div className="row mb-3">
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example7">Description:</label>
              <input type="text" id="form6Example7" name='Service' value={values.Service} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example7">DoctorId:</label>
              <input type="number" id="form6Example7" name='Doctor' value={values.Doctor} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
          </div>
        </div>
        <div className="row mb-3">
      
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example9">BP:</label>
              <input type="number" id="form6Example9" name='BP' value={values.BP} onChange={handleChange} onBlur={handleBlur} className="form-control"  required/>
            </div>

          </div>
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example10">Weight:</label>
              <input type="number" id="form6Example10" name='Weight' value={values.Weight} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
          </div>
        </div>
        <div className="row mb-3">
      
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example11">Date:</label>
              <input type="text" id="form6Example11" name='Date' value={values.Date} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example13">Temp[Celcius]:</label>
              <input type="number" id="form6Example13" name='Temp' value={values.Temp} onChange={handleChange} onBlur={handleBlur} className="form-control"  required/>
            </div>
          </div> 
             </div >
          <div className="row mb-3">
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example15">DayNumber:</label>
              <input type="number" id="form6Example15" name='DayNumber' value={values.DayNumber} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
          </div> 
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example16">Invoice Number:</label>
              <input type="number" id="form6Example16" name='Invoice' value={values.Invoice} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
          </div>
          </div>
          <div className="row mb-3">
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example17">ValidUpTO:</label>
              <input type="text" id="form6Example17" name='ValidUpTO' value={values.ValidUpTO} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
          </div> 
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example18">Amount:</label>
              <input type="number" id="form6Example18" name='Amount' value={values.Amount} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
          </div>
          </div>
          <div className="row mb-3">
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="form6Example1">Discount:</label>
              <input type="text" id="form6Example1" name='discount' value={values.discount} onChange={handleChange} onBlur={handleBlur} className="form-control" required />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
            <label className="form-label" htmlFor="opratorId">OperatorId:</label>
              <input type="text" id="opratorId" name='opratorId' value={values.opratorId} onChange={handleChange} onBlur={handleBlur}  className="form-control"  required/>
            </div>
          </div>
        </div>
                <br/>
                {loading==false?<button type='submit' className='btn btn-info'>Save</button>:<button className=' btn btn-info lds-ellipsis' disabled><div></div><div></div><div></div><div></div></button>}              
                
               
                
            </form>
        )
        
        }
        
        </Formik> 
            </div>
          </div>
        </div>
      </div> 


    

      {/* List OPD */}
 
        <h1 className=' text-info-emphasis text-center align-content-center align-center'><b>OPD-List</b><img className='mb-3' width={60} height={60} src='https://img.icons8.com/avantgarde/512/overview-pages-3.png'/></h1>
        <Formik
       enableReinitialize
       initialValues={{StartDate:"",EndDate:""}}
       onSubmit={(values,{resetForm})=>{
        setloading(true);
        axios.get(`https://opddemo.kmaschool.com/api/pannel/opd/patient?StartDate=${values.StartDate}&EndDate=${values.EndDate}`).then(res=>{setmaindata(res.data);resetForm();}).catch(err=>{props.showalert("No Data Found!!","danger"); resetForm()});
         
            
            setloading(false);

       }}>
        {({ values,handleChange,handleSubmit,})=>(
            <form onSubmit={handleSubmit}>
        <div className="container my-5">
  <div className="row my-3 ">
    <div className="col-md mx-2 p-3 bg-body-secondary">
      <b>From:<input type="date" name='StartDate' value={values.StartDate} onChange={handleChange} required className='form-control'/></b>
    </div>
    <div className="col-md ms-2 p-3 bg-body-secondary">
      <b>TO:<input type="date" required className='form-control' name='EndDate' value={values.EndDate} onChange={handleChange}/></b>
    </div>
    <div className="col-1 my-4">
      <button type='submit' className='btn btn-info'>Search</button>
    </div>
  </div>
</div>
</form>
        )      
        }
        
        </Formik>  
<div className="table-responsive mt-5">
                  <table id="example" className="table table-striped table-bordered border-1" >
        <thead>
            <tr className='bg-primary-subtle'>
                <th>Sr No.</th>
                <th>Patient</th>
                <th>OPD No.</th>
                <th>DOB</th>
                <th>Invoice No.</th>
                <th>Doctor</th>
                <th>Desciption</th>
                <th>Amount</th>
                <th>BP</th>
                <th>Weight</th> 
                <th>Temprature</th>
                <th>Date/Day</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
          {(!maindata.length>0?props.data:maindata).map((items,index)=>(

            <tr key={index}>
                <td>{index+1}</td>
                <td>{items.name}</td>
                <td>{index+1}</td>
                <td>{items.dob}</td>
                <td>{items.invoiceNumber}</td>
                <td>{items.doctorId}</td>
                <td>{items.description}</td>
                <td>{items.amount}</td>
                <td>{items.bp}</td>
                <td>{items.weight}</td> 
                <td>{items.temp}</td>
                <td>{items.date}/{items.dayNumber}</td>
                <td><button className=' btn btn-outline-info mx-3' onClick={()=>setid(items.id)} data-toggle="modal"  data-target="#exampleModal">Edit</button> <button className='mx-2  btn btn-outline-danger' >Delete</button></td>
            </tr>
          ))} 
            </tbody>
            </table></div>
    </div>
  )
}

export default HOCAPI(OpdList,"/patient/opd")