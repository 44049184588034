import React, { useContext, useEffect, useRef, useState } from 'react'
import Pagetitle from '../../../CommonHOC/Pagetitls/Pagetitle'
import { Provider } from '../../../Contexts/Context'
import {Formik} from 'formik'
import {Link} from 'react-router-dom'
import { EditDepartment, GetDepartmentbyID } from '../../ApiComponents/DepartmentApi.js/Departmentapi';
import HOCAPI from '../../../CommonHOC/APIHOC/HOCAPI'

function DepartmentList(props) {
  const context=useContext(Provider);
  const closeref=useRef();
  const [id,setid]=useState();
  const [editdata,seteditdata]=useState("");
  const HandleEdit=(id)=>{
    setid(id);
    GetDepartmentbyID(id).then(res=>seteditdata(res.data)).catch(err=>{props.showalert("Some Error Ocuured")})
  }
 
  useEffect(() => { 
    context.getdepartmentlist();
  }, []);
  return (
    <div>
      
      <Pagetitle title="Department" parent='Home' parenturl='/' />
      <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
    <div className="modal-header text-center">
              <h4 className="modal-title w-100 font-weight-bold">Update Department</h4>
              <button required type="button" ref={closeref}  className="btn btn-outline-danger" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
      <div className="modal-body">
      <Formik
       enableReinitialize
       initialValues={{
         name:editdata.name,
         }}

      onSubmit={async(values,{resetForm})=>{ 
        const data={name:values.name}
    
          props.Edit(id,data).then(res=>{closeref.current.click();props.Get();}).catch(err=>alert("Some Error Occured!!12"))

        }}>
        {({
          values,  
          handleChange,
                 handleSubmit
                }
        )=>(
              <form className='my-5' onSubmit={handleSubmit}>
                <label htmlFor="inputPassword1" className="form-label">Name:</label>
                <input required type="text" id="inputPassword1" name='name' value={values.name} onChange={handleChange} className="form-control" placeholder='Enter Service   Name' aria-describedby="passwordHelpBlock" />
                <br/>
                <button className='btn btn-primary mt-2 mx-5' type='submit'>Save</button>
              </form>
)}
        </Formik>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
        <table className="table table-hover my-5">
  <thead>
    <tr>
      <th scope="col">Sr No#</th>
      <th scope="col">Name</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    {props.data.map((value,index)=>(

    <tr key={value.id}>
      <th scope="row">{index+1}</th>
      <td>{value.name}</td>
      <td><button type="button" className="btn btn-outline-primary" onClick={()=>HandleEdit(value.id)}   data-toggle="modal" data-target="#exampleModal">Edit</button><button className='btn btn-outline-danger mx-2' onClick={()=>props.Delete(value.id)} >Delete</button></td>
    </tr>
    ))}
    
  </tbody>
</table>
<br/><Link className='btn btn-outline-info' to="/department/add">Add Department</Link>
    </div>
  )
}

export default HOCAPI(DepartmentList,"/department")
