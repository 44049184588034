import React, { useContext, useEffect } from 'react'
import Pagetitle from '../../../CommonHOC/Pagetitls/Pagetitle'
import { json, useParams } from 'react-router-dom'
import { AddEmployee } from '../../ApiComponents/EmployeeApi/EmployeesApi';
import { Formik } from 'formik';
import { Provider } from '../../../Contexts/Context';
import HOCAPI from '../../../CommonHOC/APIHOC/HOCAPI';

 function AddEmploye(props) {
    const params=useParams();
    const context=useContext(Provider);
    useEffect(() => {
      if(params.id!=null){
        props.GetbyId(params.id);
      }
    },[]);
    const addemployee=async(values)=>{
 const data=await JSON.stringify(values);
      AddEmployee(data).then(res=>{alert("Employee Added Successfully!")}).catch(err=>alert("Employee Not added!!"))
    }
  return (
    <div>
        <Pagetitle title={ !params.id? `Add Employee`:"Edit Employee"}/>
        <div className="row">
                                     <div className="col-md-12"> <span>{ !params.id? `Add Employee`:"Edit Employee"} By Filling the Details.</span> </div>
                                 </div>
        <Formik
        enableReinitialize
  initialValues={{
    firstName:props.data?props.data.firstName:"",
    middleName: props.data?props.data.lastName:"",
    lastName: props.data?props.data.lastName:"",
    mobileNo: props.data?props.data.mobileNo:"",
    alternateMobileNo:props.data?props.data.alternateMobileNo:"",
    emailId: props.data?props.data.emailId:"",
    gender: props.data?props.data.gender:"",
    dataOfJoin:props.data?props.data.dataOfJoin:"",
    dataOfBirth: props.data?props.data.dataOfBirth:"",
    createddate: props.data?props.data.createddate:"",
    creatorid: context.user,
    hospitalId: 1,
    code: "",
    password: "",
    employeeTypeId: ""
  }}
  onSubmit={(values)=>{
 {!params.id?addemployee(values):alert("Editing Employee Details")}
  }}
        >
          {({values,handleChange,handleBlur,handleSubmit})=>(
            <form onSubmit={handleSubmit}>
   <div className="my-4">
                             <div id="step-1">
                             <div className="row my-3">
                                     <div className="col-md-6"><label className='form-label'>FirstName-:</label><input type="text" className="form-control" placeholder="Name" name='firstName' onChange={handleChange} value={values.firstName} required/> </div>
                                     <div className="col-md-6"> <label className='form-label'>MiddleName</label><input type="text" className="form-control" placeholder="Middle Name.." name='middleName' onChange={handleChange} value={values.middleName} required/> </div>
                                 </div>
                                 <div className="row my-3">
                                     <div className="col-md-6"><label className='form-label'>LastName-:</label> <input type="text" className="form-control" placeholder="Last Name.." name='lastName' onChange={handleChange} value={values.lastName} required/> </div>
                                     <div className="col-md-6"> <label className='form-label'>Email</label><input type="email" className="form-control" placeholder="Email.." name='emailId' onChange={handleChange} value={values.emailId} required/> </div>
                                 </div>
                                 <div className="row my-3">
                                 <div className="col-md-6"><label className='form-label'>Mobile Number</label> <input type="number" className="form-control" placeholder="Mobile Number..." name='mobileNo' onChange={handleChange} value={values.mobileNo} required/> </div>
                                 <div className="col-md-6"><label className='form-label'>Alternate MobileNumber</label> <input type="number" className="form-control" placeholder="Alternate Mobile Number.." name='alternateMobileNo' onChange={handleChange} value={values.alternateMobileNo} required/> </div>
                                 </div>
                             </div>
                             <div id="step-2">
                                 <div className="row">
                                  
                                 <div className="col-md-6"><label className='form-label'>Gender</label><select name='gender' className='form-control' onChange={handleChange} value={values.gender} required><option>Choose Your Option-:</option><option value="Male">Male</option><option value="Female">Female</option><option value="Others">Others</option></select> </div>
                                 <div className="col-md-6"><label className='form-label'>DOB</label> <input type="date" className="form-control" placeholder="Date of Birth.." name='dataOfBirth' onChange={handleChange} value={values.dataOfBirth} required/> </div>
                                 </div>
                                 <div className="row my-3">
                                     <div className="col-md-6"><label className='form-label'>Join Date</label>  <input type="date" className="form-control" name="dataOfJoin" onChange={handleChange} value={values.dataOfJoin} required/> </div>
                                     <div className="col-md-6"><label className='form-label'>EmployeeType</label> <input type="text" name="employeeTypeId" onChange={handleChange} className="form-control" value={values.employeeTypeId} placeholder="Country" required/> </div>
                                 </div>
                             </div>
                             <div id="step-3" className={`d-${!params.id?"block":"none"}`}>
                                 <div className="row">
                                     <div className="col-md-6"><label className='form-label'>Code-:</label><input type="text" className="form-control" placeholder="Code..." value={values.code} onChange={handleChange} name='code' required={!params.id?true:false}/> </div>
                                     <div className="col-md-6"><label className='form-label'>Password-:</label> <input type="text" className="form-control" placeholder="Your Password.." onChange={handleChange} value={values.password} required={!params.id?true:false}/> </div>
                                 </div>
                             </div>
                             <div id="step-4" className="">
                                 <div className="row my-5">
                                      <button type='submit' className='btn btn-primary'>SUbmit</button>
                                 </div>
                             </div>
                         </div>
 
            </form>
          )}

        </Formik>
    </div>
  )
}

export default HOCAPI(AddEmploye,"/employeeloginboth")