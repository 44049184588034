import Baseurl from "../Baseurl/Baseurl";



const GetServiceType=()=>Baseurl.get("/service/type");
const AddServiceType=(data)=>Baseurl.post("/service/type",data);
const EditServiceType=(id,data)=>Baseurl.patch(`/service/type/${id}`,data);
const GetServiceTypeById=(id)=>Baseurl.get(`/service/type/${id}`);

export {
    GetServiceType,AddServiceType,EditServiceType,GetServiceTypeById
}