import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Context } from './Contexts/Context';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import Alert from './Alerts/Alert';


const Maincomp=()=>{
  const [alert, setAlert] = useState(null);
  let showalert = (message, type) => {
    setAlert({
      message: message,
      type: type
    })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }
  const navigate=useNavigate();
  return(
    <>
<Alert alert={alert} />    
<Context showalert={showalert}  navigate={navigate} >
  <App/>
</Context>
</>
  )
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
   <Maincomp/>
   </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
