
import React,{useContext, useEffect, useRef, useState} from 'react';
import { Formik, Field } from 'formik';    
import Pagetitle from '../../../../CommonHOC/Pagetitls/Pagetitle';
import Baseurl from '../../../ApiComponents/Baseurl/Baseurl'; 

export default function EmployeeType(props) { 
const closeref=useRef(null); 
const [data, setdata] = useState([]);
const [id,setid]=useState(null);
const [loading, setloading] = useState(false);
const [editdata, seteditdata] = useState([]);
 
const handledelete=(id)=>{
  const confirm= window.confirm("Are you Sure?");
  if(confirm){ 
     Baseurl.delete(`/employees/Types/${id}`).then(res=>{props.showalert("Deleted Succesfully!!","success"); get()}).catch(err=>props.showalert("Error Occurred!!","danger"))

}
    
}

const seteditfunc=(id)=>{ 
setid(id); 
Baseurl.get(`/employees/Types/${id}`).then(res=>{seteditdata(res.data)}).catch(err=>props.showalert("error Occured!!","danger")) 
}

const get=()=>{
    Baseurl.get("/employees/Types").then((result) => {
        setdata(result.data)
    }).catch((err) => {
       setdata([]);
    });
}
useEffect(() => { 
  get();
}, []);
  return (
    <div>
   <div className="container ">
  <>

<div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
    <div className="modal-header text-center">
              <h4 className="modal-title w-100 font-weight-bold">Update Service List</h4>
              <button required type="button" ref={closeref}  className="btn btn-outline-danger" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
      <div className="modal-body">
      <Formik
       enableReinitialize
       initialValues={{
         name:editdata.name
        }}

      onSubmit={async(values,{resetForm})=>{ 
         Baseurl.patch(`/employees/Types/${id}`,{name:values.name}).then(res=>{closeref.current.click();  get();props.showalert("Updated Successfully!!","success")}).catch(err=>{props.showalert("Some Error Occured!!","danger");})
       
        }}>
        {({
          values,  
          handleChange,
                 handleSubmit
                }
        )=>(
              <form className='my-5' onSubmit={handleSubmit}>
                <label htmlFor="inputPassword1" className="form-label">Name:</label>
                <input required type="text" id="inputPassword1" name='name' value={values.name} onChange={handleChange} className="form-control" placeholder='Enter Service   Name' aria-describedby="passwordHelpBlock" />
                <button className='btn btn-primary mt-2 mx-5' type='submit'>Save</button>
              </form>
)}
        </Formik>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>


    <div className=" row d-flex justify-content-center">
           
      <Pagetitle title='Employee Type'  />
          <div className="card-body">
    <div>
       <Formik
       enableReinitialize
       initialValues={{
        name:"" ,
       }}
       onSubmit={async(values,{resetForm})=>{
        const data=await JSON.stringify(values);  
     Baseurl.post("/employees/Types",data).then((result) => {
        props.showalert("Added Successfully!!","success");
        resetForm();
        get();

     }).catch((err) => {
        props.showalert("Error Occurred!!","danger");
     });
     
      }}
       
       >
        {(
            {
                values,
                 handleChange,
                 handleSubmit,
                 handleBlur,
                 errors,
                 touched
            }
        )=>(
            <form onSubmit={handleSubmit} className='my-5'>
         <label htmlFor="inputPassword5" className="form-label">Name:</label>
<input type="text"  name='name' value={values.name} onChange={handleChange} onBlur={handleBlur} className="form-control" placeholder='Enter Employee Type Name' aria-describedby="passwordHelpBlock" required/>
                <br/>
               <button type='submit' className='btn btn-info'>Submit</button>
            </form>
        )
        
        }
        
        </Formik> 
        </div>
        </div>
        </div>
        <div className="table-responsive my-3">
                  <table  className="table table-striped table-bordered border-1"  >
    <thead>
      <tr>
        <th >SRNO.</th>
        <th >Name</th> 
        <th >Action</th>
      </tr>
    </thead>
 <tbody>
        {data.map((items,index)=>(
      <tr key={index}>
        <td>{index+1}</td>
        <td>{items.name}</td> 
         <td><button type="button" className="btn btn-primary" onClick={()=>seteditfunc(items.id)}  data-toggle="modal" data-target="#exampleModal">Edit</button> <button className='btn btn-outline-danger mx-1' onClick={()=>handledelete(items.id)}>Delete</button></td> 
      </tr>
))}
    </tbody> 

  </table>
</div></>
                </div>
 

    </div>
  )
}
