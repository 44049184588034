import React, { useEffect } from 'react'
import { useContext } from 'react' 
import { useRef } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { Formik } from 'formik';
import HOCAPI from '../../../CommonHOC/APIHOC/HOCAPI';
import Baseurl from '../../ApiComponents/Baseurl/Baseurl';

 function Pateint(props) { 
  const [id, setid] = useState(null);
  const closeref=useRef(null); 
  const [loading, setloading] = useState(false);
  const showmodalPatient=useRef();  
    const [maindata, setmaindata] = useState([]);
  
    const handledelete=(id)=>{
      const confirm= window.confirm("Are you Sure?");
      if(confirm){ 
         props.Delete(id);
    
    } 
    }

  const [editdata, seteditdata] = useState([]);
  let  displayRadioValue=(e)=> {
    var ele = document.getElementsByTagName('input');
    for(let i = 0; i < ele.length; i++) {
          
        if(ele[i]. type="radio") {
          
            if(ele[i].checked)
            e.preventDefault();
        
        }
    }
  }


  const handleedit=(id)=>{
setid(id); 
Baseurl.get(`/patient/opd/${id}`).then(res=>{seteditdata(res.data);showmodalPatient.current.click();}).catch(err=>props.showalert("Error Occured!!","danger"))
  }
  return (
    <div >
      <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
    <div className="modal-header text-center">
              <h4 className="modal-title w-100 font-weight-bold">Update Patient Details</h4>
              <button required type="button" ref={closeref}  className="btn btn-outline-danger" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
      <div className="modal-body">
      <Formik
       enableReinitialize
       initialValues={{
        name: editdata.name,
        gender:editdata.gender,
        dob: editdata.dob,
        maritalStatus:editdata.maritalStatus,
        mobileNo: editdata.mobileNo,
        email: editdata.email,
        address1: editdata.address1,
        address2:editdata.address2,
        city: editdata.city,
        state: editdata.state,
        pincode: editdata.pincode
        }}

      onSubmit={async(values,{resetForm})=>{ 
        const data=await JSON.stringify(values);
         Baseurl.patch(`/patient/${id}`,data).then(res=>{closeref.current.click();props.Get(); props.showalert("Updated Successfully!!","success")}).catch(err=>{props.showalert("Some Error Occured!!","danger");})
       
        }}>
        {({
          values,  
          handleChange,
                 handleSubmit
                }
        )=>(
              <form className='my-5' onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col'>
                <label htmlFor="inputPassword1" className="form-label">Name:</label>
                <input required type="text" id="inputPassword1" name='name' value={values.name} onChange={handleChange} className="form-control" placeholder='Enter Patient Name' aria-describedby="passwordHelpBlock" />
                  </div>
                  <div className='col'>
                <label htmlFor="inputPassword1" className="form-label">Gender:</label>
                <select required type="text" id="inputPassword1" name='gender' value={values.gender} onChange={handleChange} className="form-control"  aria-describedby="passwordHelpBlock" >
                <option>Choose One-:</option>
                  <option value="Male">Male</option>
                  <option value="Female">FeMale</option>
                </select>
                  </div>
                </div>  <div className='row'>
                  <div className='col'>
                <label htmlFor="inputPassword1" className="form-label">DOB:</label>
                <input required type="text" id="inputPassword1" name='dob' value={values.dob} onChange={handleChange} className="form-control"  aria-describedby="passwordHelpBlock" />
                    
                  </div>
                  <div className='col'>
                <label htmlFor="inputPassword1" className="form-label">MaritalStatus:</label>
                <select required type="text" id="inputPassword1" name='maritalStatus' value={values.maritalStatus} onChange={handleChange} className="form-control"  aria-describedby="passwordHelpBlock">
                  <option>Choose One-:</option>
                  <option>Married</option>
                  <option>UnMarried</option>
                </select>

                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    
                <label htmlFor="inputPassword1" className="form-label">MobileNo.:</label>
                <input required type="number" id="inputPassword1" name='mobileNo' value={values.mobileNo} onChange={handleChange} className="form-control"  aria-describedby="passwordHelpBlock" />
                  </div>
                  <div className='col'>
                <label htmlFor="inputPassword1" className="form-label">Email:</label>
                <input required type="email" id="inputPassword1" name='email' value={values.email} onChange={handleChange} className="form-control"  aria-describedby="passwordHelpBlock" />

                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    
                <label htmlFor="inputPassword1" className="form-label">Address1:</label>
                <input required type="text" id="inputPassword1" name='address1' value={values.address1} onChange={handleChange} className="form-control"  aria-describedby="passwordHelpBlock" />
                  </div>
                  <div className='col'>

                <label htmlFor="inputPassword1" className="form-label">Address2:</label>
                <input required type="text" id="inputPassword1" name='address2' value={values.address2} onChange={handleChange} className="form-control"  aria-describedby="passwordHelpBlock" />
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    
                <label htmlFor="inputPassword1" className="form-label">City:</label>
                <input required type="text" id="inputPassword1" name='city' value={values.city} onChange={handleChange} className="form-control"  aria-describedby="passwordHelpBlock" />
                  </div>
                  <div className='col'>

                <label htmlFor="inputPassword1" className="form-label">State:</label>
                <input required type="text" id="inputPassword1" name='state' value={values.state} onChange={handleChange} className="form-control"  aria-describedby="passwordHelpBlock" />
                  </div>
                </div>
                <label htmlFor="inputPassword1" className="form-label">Pincode:</label>
                <input required type="number" id="inputPassword1" name='pincode' value={values.pincode} onChange={handleChange} className="form-control"  aria-describedby="passwordHelpBlock" />
                <button className='btn btn-primary mt-2 mx-5' type='submit'>Save</button>
              </form>
)}
        </Formik>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

  <>
 
        <h1 className=' text-info-emphasis text-center align-content-center'>Patient <img width={60} height={60} src='https://img.icons8.com/external-flaticons-flat-flat-icons/512/external-patient-nursing-flaticons-flat-flat-icons-3.png'/></h1>
       
        
        <div  className="container my-5">
<div className="form-outline  d-inline-flex my-3"  >
<Formik
       enableReinitialize
       initialValues={{Name:""}}
       onSubmit={async(values,{resetForm})=>{
         setloading(true);
         axios.get(`https://opddemo.kmaschool.com/api/pannel/opd/patient?Name=${values.Name}`).then(res=>{
           
           setmaindata(res.data);resetForm();
        })
        
        .catch(err=>{

          props.showalert("No Data Found!!","danger"); resetForm()
        })
        setloading(false);
       }}>
        {({ values,handleChange,handleSubmit,})=>(
            <form onSubmit={handleSubmit}className="form-inline d-inline-flex my-2  my-lg-0">
                      <label className="form-label m-2" htmlFor="form6Example3">Name:</label>
      <input className="form-control mr-sm-2 " type="text" name='Name' required placeholder="Name..." value={values.Name} onChange={handleChange} aria-label="Search"/>
      <button className="btn btn-outline-success ms-3 " type="submit">Search</button>
    </form>
    )      
        }
        
        </Formik>
        
      </div>
      <div className="form-outline  d-inline-flex my-3"  >
    <Formik
       enableReinitialize
       initialValues={{StartDate:"",EndDate:""}}
       onSubmit={(values,{resetForm})=>{
        setloading(true);
          axios.get(`https://opddemo.kmaschool.com/api/pannel/opd/patient?StartDate=${values.StartDate}&EndDate=${values.EndDate}`).then(res=>{setmaindata(res.data);resetForm();}).catch(err=>{props.showalert("No Data Found!!","danger"); resetForm()});
   setloading(false);

       }}>
        {({ values,handleChange,handleSubmit,})=>(
            <form onSubmit={handleSubmit}>
    <div className="row ">
                  <div className="col-6">
                    <div className="form-outline d-inline-flex">
                      <label className="form-label m-2" htmlFor="form6Example3">StartDate:</label>
<input type="date" id="date" name='StartDate' className="form-control" value={values.StartDate} onChange={handleChange}    required />

                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-outline d-inline-flex">
                      <label className="form-label m-2" htmlFor="form6Example4">EndDate:</label>
                      <input  type="date" id="form6Example4" name='EndDate' required value={values.EndDate} onChange={handleChange}  className="form-control" />
    <button className= ' btn btn-info mx-3'>Find</button>

                    </div>
                    
                  </div>
                </div>
    </form>
        )      
        }
        
        </Formik>
        </div>

                    </div>


<div className="table-responsive my-5"> 
{maindata.length>0||props.data.length>0? <table id="example" className="table table-striped table-bordered border-1"  >
        <thead>
            <tr  className='bg-primary-subtle'>
                <th>Sr No.</th>
                <th>Name</th>
                <th>Gender</th>
                <th>DOB</th>
                <th>Marital</th>
                <th>Mobile No</th>
                <th>Email</th>
                <th>Address1</th>
                <th>Address2</th>
                <th>City</th>
                <th>State</th>
                <th>Pincode</th>
                <th>Date</th> 
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
  

        {(!maindata.length>0?props.data:maindata).map((items,index)=>(
            <tr key={index+1}>
                <td>{index+1}</td>
                <td>{items.name}</td>
                <td>{items.gender}</td>
                <td>{items.dob}</td>
                <td>{items.maritalStatus}</td>
                <td>{items.mobileNo}</td>
                <td>{items.email}</td>
                <td>{items.address1}</td>
                <td>{items.address2}</td>
                <td>{items.city}</td>
                <td>{items.state}</td>
                <td>{items.pincode}</td>
              
                <td>{items.date}</td> 
                <td><button className=' btn btn-outline-info' data-toggle="modal" data-target="#exampleModal" onClick={()=>handleedit(items.id)}>Edit</button> <button className='mx-2 btn btn-outline-danger'  onClick={e=>handledelete(items.id)}>Delete</button></td>
            </tr>
                   ))} 
            </tbody>
            </table>:<h3 className='text-danger'>No Data To Show!!</h3>}

            </div>
        </>
                 
    </div>
  )
}


export default  HOCAPI(Pateint,"/patient/opd");
